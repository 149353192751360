import React from "react";
import { CreateToolbar } from "../../CreateToolbar";
import {
  Create,
  NumberInput,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  ImageInput,
  ImageField,
  SimpleForm,
  FormTab,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import moment from "moment";
export const InsurerCreate = (props) => {
  const classes = useStyles({});
  let defaultValues = {
    
    dealDate: moment().toISOString(),
    openDate:moment().toISOString(),
    
  };
  return (
    <Create title="Insurer Create" {...props}>

<SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />} initialValues={defaultValues}>
          
          <TextInput source="code" label="Code" validate={[required()]} formClassName={classes.one_three_input} disabled={true} initialValue={"Auto Generate"} />
          <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />
          <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.last_three_input} />

          <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.one_three_input} />
          <TextInput source="phoffice" label="Phone(Off)."  formClassName={classes.two_three_input} />
          <TextInput source="email" label="email" formClassName={classes.last_three_input} />
          
          
          <TextInput source="phmobile" label="Mobile"  formClassName={classes.one_three_input} />
          <TextInput source="tin" label="Tin"   formClassName={classes.two_three_input} />
          <TextInput source="cstNo" label="CST Number"   formClassName={classes.last_three_input} />

          <TextInput source="tpt" label="tpt"  formClassName={classes.one_three_input} />
          <TextInput source="licno" label="LIC No"   formClassName={classes.two_three_input} />
          <TextInput source="panno" label="Pan Number"   formClassName={classes.last_three_input} />
          <div style={{width:"100%"}} ><h4>Billing Address Detail</h4></div>
          <div style={{width:"100%"}}><hr/></div>

          <TextInput source="state" label="Bill State" validate={[required()]} formClassName={classes.one_three_input} />
        <ReferenceInput label="Bill  Area" source="areaId" reference="areas"       perPage={20000}      sort={{ field: 'name', order: 'ASC' }} formClassName={classes.two_three_input}>
            <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="city" label="Bill City" validate={[required()]} formClassName={classes.last_three_input} />
        
        
        <TextInput source="add1" label="Bill Address 1" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="add2" label="Bill Address 2" formClassName={classes.two_three_input} />
        <TextInput source="add3" label="Bill Address 3"  formClassName={classes.last_three_input} />

        <TextInput source="pincode" label="Bill Pincode" validate={[required()]} formClassName={classes.one_three_input} />

         
        <TextInput source="cpName1" label="Bill Contact Person 1"  formClassName={classes.two_three_input} />
        <TextInput source="stateCode" label="State Code" validate={[required()]}  formClassName={classes.last_three_input} />
        
        <div  style={{width:"100%"}}><h4>Shipping Address Detail</h4></div>
        <div style={{width:"100%"}}><hr/></div>
        <TextInput source="shipState" label="Ship State" validate={[required()]} formClassName={classes.one_three_input} />
        <ReferenceInput label="Ship  Area" source="shipAreaId" reference="areas" perPage={20000}  sort={{ field: 'name', order: 'ASC' }}formClassName={classes.two_three_input}>
            <SelectInput optionText="name" validate={[required()]} />
        </ReferenceInput>
        <TextInput source="shipCity" label="Ship City" validate={[required()]} formClassName={classes.last_three_input} />
        
        
        <TextInput source="shipAdd1" label="Ship Address 1" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="shipAdd2" label="Ship Address 2" formClassName={classes.two_three_input} />
        <TextInput source="shipAdd3" label="Ship Address 3"  formClassName={classes.last_three_input} />

        <TextInput source="shipPincode" label="Ship Pincode" validate={[required()]} formClassName={classes.one_three_input} />

         
        <TextInput source="shipCpName1" label="Ship Contact Person 1"  formClassName={classes.two_three_input} />
        
        <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.last_three_input} />

        <TextInput source="cpRes1" label="Contact Person 1 ResiID"  formClassName={classes.one_three_input} />
        <TextInput source="cpName2" label="Contact Person 2"  formClassName={classes.two_three_input} />
        <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.last_three_input} />
        
        <TextInput source="cpRes2" label="Contact Person 2 ResiID"  formClassName={classes.one_three_input} />
        <TextInput source="cpName3" label="Contact Person 3"  formClassName={classes.two_three_input} />
        <TextInput source="cpMob3" label="Contact Person 3 Mobile" formClassName={classes.last_three_input} />

        <TextInput source="cpRes3" label="Contact Person 3 ResiID"  formClassName={classes.one_three_input} />
        <TextInput source="cpName4" label="Contact Person 4"  formClassName={classes.two_three_input} />
        <TextInput source="cpMob4" label="Contact Person 4 Mobile" formClassName={classes.last_three_input} />

        <TextInput source="cpRes4" label="Contact Person 4 ResiID"  formClassName={classes.one_three_input} />
        <TextInput source="cpName5" label="Contact Person 5"  formClassName={classes.two_three_input} />
        <TextInput source="cpMob5" label="Contact Person 5 Mobile" formClassName={classes.last_three_input} />

        <TextInput source="cpRes5" label="Contact Person 5 ResiID"  formClassName={classes.one_three_input} />
        <ReferenceInput label="Handle By" source="employeeId" reference="employees" formClassName={classes.two_three_input}
          sort={{ field: 'name', order: 'ASC' }}
          >
            <SelectInput optionText="name" validate={[required()]}  formClassName={classes.two_three_input} />
        </ReferenceInput>

        <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" filter={{ type: 'TT' }} reference="lookups" formClassName={classes.last_three_input}>
                    <SelectInput optionText="name"  />
          </ReferenceInput>
          <NumberInput  label="Tax Percentage"  source="taxPercentage"   formClassName={classes.one_three_input} /> 
          <TextInput source="shipStateCode" validate={[required()]}   label="Ship State Code"  formClassName={classes.two_three_input} />
            <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.last_three_input} />
            
          
          
           
        
     
        

      </SimpleForm>


    </Create>
  );
};
