import React, { useState, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import * as apiClient from "../common/apiClient";
import { makeStyles } from '@material-ui/core/styles';
import CountBlock from "./CountBlock";
import HolidayList from "./HolidayList";
import { Create, SimpleForm, TextInput, BooleanInput, DateInput, useNotify, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CustomCalendar from './customcalender/CustomCalendar';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CasesList from './CasesList';
import exportFromJSON from 'export-from-json'
const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});
const Dashboard = () => {
    const [db, setdb] = useState([]);
    const [dbExport, setdbExport] = useState([]);
    
    const [fetchingAttedanceStatus, setFetchingAttedanceStatus] = useState(true);
    const [attType, setAttType] = useState("");
    const [workLocation, setWorkLocation] = useState("");
    const [attOnOff, setattOnOff] = useState(false);
    const notify = useNotify();
    const styles = useStyles({});
    const [holiday, setHoliday] = useState([]);
    var myDate = new Date();

    const [fromdate, setFromDate] = useState( new Date(myDate.setTime( myDate.getTime() - 365 * 86400000 )));
    const [todate, setToDate] = useState(new Date());
    const [insurerId, setInsurerId] = useState(0);
    const [tpaId, setTpaId] = useState(0);
    const [foId, setFOId] = useState(0);
    const [hospitalId, setHospitalId] = useState(0);   
    const loadDashboard = () => {
        let userid = localStorage.getItem("userId");
        setdb([]);
        let data={
            UserID:userid,
            FromDate:fromdate,
            ToDate:todate,
            Ord:0,
            Type:'SUMMARY',
            RepTp:'',
            FOID:foId,
            HospitalID:hospitalId,
            TPAId:tpaId,
            InsurerID:insurerId 
        };
        //new Date().setDate(new Date().getDate() -365)
    
        apiClient.get_dashboard_v1(data).then(res => {
            setdb(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const GetDashboardHoliday = () => {
        apiClient.GetDashboardHoliday().then(res => {
            //console.log(res);
            setHoliday(res);
        }).catch(err => {
            console.log(err);
        });
    }
    const handleAttendance = (event) => {
        if (workLocation === null || workLocation === "") {
            notify("Please Select Work location", "warning")
            return;
        }
        if (attType === "ATT_END" && window.confirm('Do you really want to end your working?')) {
            updateAttedance();
        }
        if (attType === "ATT_START" && window.confirm('Do you really want to Start your working?')) {
            updateAttedance();
        }
    }
    const updateAttedance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        let formBody = {
            userId: userid,
            attType: attType,
            location: workLocation
        }
        apiClient.updateAttendance(formBody).then(res => {
            notify(res[0].Msg)
            loadAttendance();
        }).catch(err => {
            console.log(err);
        });
    }
    function exportToCSV(data) {
        const fileName = 'download'
        const exportType = 'xls'
        exportFromJSON({ data, fileName, exportType })
    
      }
    const onExport = (OrdId,Key) => {

        let userid = localStorage.getItem("userId");
        setdbExport([]);
        let data={
            UserID:userid,
            FromDate:fromdate,
            ToDate:todate,
            Ord:OrdId,
            Type:'Detailed',
            RepTp:Key,
            FOID:foId,
            HospitalID:hospitalId,
            TPAId:tpaId,
            InsurerID:insurerId 
        };
        //new Date().setDate(new Date().getDate() -365)
    
        apiClient.get_dashboard_v1(data).then(res => {
            
            exportToCSV(res);
        }).catch(err => {
            console.log(err);
        });
        //alert(Key)
    }
    const loadAttendance = () => {
        setFetchingAttedanceStatus(true);
        let userid = localStorage.getItem("userId");
        apiClient.get_attendancestatus(userid).then(res => {

            setAttType(res[0].Msg);
            setWorkLocation(res[0].location)
            if (res[0].Msg === 'ATT_START') {
                setattOnOff(false)
            }
            if (res[0].Msg === 'ATT_END') {
                setattOnOff(true)
            }

            setFetchingAttedanceStatus(false);
        }).catch(err => {
            //alert(JSON.stringify(err))
            console.log(err);
        });
    }
    useEffect(() => {
        loadDashboard();
        loadAttendance();
        GetDashboardHoliday();
    }, []);

    return (
        <SimpleForm toolbar={null}>
            <div style={{ width: "100%" }}>

                {!fetchingAttedanceStatus && <div style={{ display: "flex", marginTop: "6px", padding: "6px", fontSize: "12px", color: "blue", border: "1px solid lightgray" }} >

                    {attType != "ATT_DONE" && <div>Mark {attType === 'ATT_START' ? ' In ' : ' Out '}  Attendance  {attType === 'ATT_END' ? '. You are working from ' : ""}</div>}
                    {attType != "ATT_DONE" && <div style={{ paddingLeft: 10 }}>
                        <select style={{ height: 30 }} onChange={(event) => setWorkLocation(event.target.value)} disabled={attType === "ATT_END" || attType === "ATT_DONE"} >
                            <option value="" selected={workLocation === null || workLocation === ""}>Select Work Location</option>
                            <option value="WFH" selected={workLocation === "WFH"} >WFH</option>
                            <option value="WFO" selected={workLocation === "WFO"}>  WFO</option>
                            <option value="WFF" selected={workLocation === "WFF"}>WFF</option>
                        </select>
                    </div>
                    }
                    {attType != "ATT_DONE" && <div>
                        <FormControlLabel
                            value="start"
                            control={<Switch color="primary" checked={attOnOff} onChange={(event) => handleAttendance(event)} />}
                            label={attType === "ATT_START" ? "Start Your Day" : "End Your Working"}
                            labelPlacement="start"
                        />
                    </div>
                    }
                    {/* {attType === "ATT_DONE" &&
                    <div>
                        You have finished your day . Your location WFO and worked 10 hrs 30 mins
                </div>} */}

                </div>
                }
                
                <div className='dashboard-filters-container' >

                    <DateInput source="fromDate" variant="outlined" initialValue={fromdate} onChange={(e) => { setFromDate(e.target.value); }}/>
                    <DateInput source="toDate" variant="outlined" initialValue={todate} onChange={(e) => { setToDate(e.target.value); }}/>

                    <ReferenceInput
                        perPage={25} sort={{ field: 'name', order: 'ASC' }}
                        filterToQuery={searchText => ({ 'name~like': searchText })}
                        allowEmpty alwaysOn
                        label="Insurer Name" source="insurerId" reference="insurers" 
                        onChange={(data) => { setInsurerId(data); }}
                        variant="outlined">
                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput> 
                    <ReferenceInput 
                        perPage={25} sort={{ field: 'name', order: 'ASC' }}
                        variant="outlined"
                        onChange={(data) => { setTpaId(data); }}
                        filterToQuery={searchText => ({ 'name~like': searchText })}
                        allowEmpty alwaysOn
                        label="TPA Name" source="tpaid" reference="tpas" >
                        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>
                    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                        variant="outlined"
                        filterToQuery={searchText => ({ 'name~like': searchText })}
                        onChange={(data) => { setFOId(data); }}
                        allowEmpty alwaysOn
                        label="Fo Name" source="foId" reference="employees" >
                        <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>
                    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                        variant="outlined"
                        onChange={(data) => { setHospitalId(data); }}
                        filterToQuery={searchText => ({ 'name~like': searchText })}
                        allowEmpty alwaysOn
                        label="Hospital Name" source="hospId" reference="hospitals" >
                        <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>
                    <div style={{textAlign:'right',justifyContent:'right',width:'100%',marginRight:"30px"}}>
                    <Button variant="contained"
                        onClick={() => {
                            loadDashboard();
                        }}
                        startIcon={<AssessmentIcon />}
                        color="primary">
                        Load 
                    </Button>
                    </div>
                   
                </div>
                <div className={styles.flexCenter}>
                        <CasesList data={db} onExport={onExport} />
                        {/* {db && db.map((item: any, index: number) => {
                            return (
                                <div key={index}>
                                    <CountBlock title={item.Descriptions} value={item.TotalCount} icon={item.IconUrl} />
                                </div>
                            )
                        })} */}

                    </div>
                <div className={styles.flex} style={{ margin: 30 }} >
                    <div >
                        <h2 style={{ textAlign: "center" }}>Attendance</h2>
                        <CustomCalendar />
                    </div>
                    <div style={{ paddingLeft: 30 }}>
                        <h2 style={{ textAlign: "center" }}>Holiday Details</h2>
                        {holiday && <HolidayList data={holiday} />}
                    </div>
                </div>

            </div>
        </SimpleForm >
    );
}

export default Dashboard;
