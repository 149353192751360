import React from "react";
import {
  Create,
  FormDataConsumer,
  SelectInput,
  TextInput,
  BooleanInput,
  required,
  ReferenceInput,
  NumberInput,
  ImageField,
  SimpleForm,
  FormTab,
  DateInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { CreateToolbar } from "../../CreateToolbar";
import moment from "moment";


export const HospitalCreate = (props) => {
  const TwoDecimalPlaces = (value) => {
    return Number(value).toFixed(2).valueOf();
}
  
  const classes = useStyles({});
  let defaultValues = {
    
    dealDate: moment().toISOString(),
    openDate:moment().toISOString()
  }  ;
  return (
    <Create title="Hospital Create" {...props}>
       <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}  initialValues={defaultValues}>
          
            <TextInput source="code" label="Code" validate={[required()]} formClassName={classes.one_three_input} disabled={true} initialValue={"Auto Generate"} />
            <DateInput source="dealDate" label="Ent. Date" validate={[required()]} formClassName={classes.two_three_input} />
            <DateInput source="openDate" label="Open Date" validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="phoffice" label="Phone(Off)."  formClassName={classes.two_three_input} />
            <TextInput source="email" label="email" formClassName={classes.last_three_input} />
            
            <TextInput source="phmobile" label="Mobile" formClassName={classes.one_three_input} />
            <TextInput source="tin" label="TIN" formClassName={classes.two_three_input} />
            <TextInput source="cstNo" label="GST Number" formClassName={classes.last_three_input} />

            <TextInput source="tpt" label="tpt" formClassName={classes.one_three_input} />
            <TextInput source="licno" label="LIC No" formClassName={classes.two_three_input} />
            <TextInput source="panno" label="PAN Number"  formClassName={classes.last_three_input} />

            <ReferenceInput  perPage={2000}  label="Handle By" source="employeeId" reference="employees" formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} >
              <SelectInput optionText="name" validate={[required()]}/>
          </ReferenceInput>

          <TextInput source="state" label="State" validate={[required()]} formClassName={classes.two_three_input} />
          <ReferenceInput  perPage={2000}  label="Area" source="areaId" reference="areas" formClassName={classes.last_three_input} sort={{ field: 'name', order: 'ASC' }}>
              <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput source="city" label="City" validate={[required()]} formClassName={classes.one_three_input} />
          
          <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.two_three_input} />
          <TextInput source="add2" label="Address 2" formClassName={classes.last_three_input} />

          <TextInput source="add3" label="Address 3"  formClassName={classes.one_three_input} />
          <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.two_three_input} />
          <TextInput source="remarks" label="Remarks"  formClassName={classes.last_three_input} />

          <BooleanInput source="isCenteralHosp" label="IGST Invoice" initialValue={false} formClassName={classes.one_three_input} />
          <ReferenceInput label="Tax Type" perPage={5000} source="taxtTypeID" filter={{ type: 'TT' }} reference="lookups" formClassName={classes.two_three_input}>
                    <SelectInput optionText="name"  />
          </ReferenceInput>
          <NumberInput  label="Tax Percentage"  source="taxPercentage"   formClassName={classes.two_three_input} /> 
          
          <BooleanInput source="isCeLic" label="Is CE License" initialValue={true} formClassName={classes.one_three_input} />
          <BooleanInput source="isFireLic" label="Is Fire Lic" initialValue={true} formClassName={classes.two_three_input} />
          <BooleanInput source="isPndt" label="Is PNDT" initialValue={true} formClassName={classes.last_three_input} />

          <BooleanInput source="isPollut" label="Is Pollution" initialValue={true} formClassName={classes.one_three_input} />
          <BooleanInput source="isBioWaste" label="Is Biomedical Waste" initialValue={true} formClassName={classes.two_three_input} />
          <BooleanInput source="isPpn" label="Is PPN" initialValue={true} formClassName={classes.last_three_input} />

          <BooleanInput source="isRohiniId" label="Is Rohini Id" initialValue={true} formClassName={classes.one_three_input} />
          <BooleanInput source="isNabh" label="isBioWaste" initialValue={true} formClassName={classes.two_three_input} />
          <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.last_three_input} />


    
          <TextInput source="cpName1" label="Contact Person 1"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes1" label="Contact Person 1 ResiID"  formClassName={classes.last_three_input} />

          <TextInput source="cpName2" label="Contact Person 2"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.two_three_input} />
          <TextInput source="cpRes2" label="Contact Person 2 ResiID"  formClassName={classes.last_three_input} />
          
          <TextInput source="cpName3" label="CE License No."  formClassName={classes.one_three_input} />
          <TextInput source="cpMob3" label="Fire License No." formClassName={classes.two_three_input} />
          <TextInput source="cpRes3" label="ROHINI ID"  formClassName={classes.last_three_input} />
          

          <TextInput source="cpName4" label="CE License Validity"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob4" label="FIRE License Validity" formClassName={classes.two_three_input} />
          <TextInput source="cpRes4" label="ROHINI ID Validity"  formClassName={classes.last_three_input} />

          <TextInput source="cpName5" label="PPN Validity"  formClassName={classes.one_three_input} />
          <TextInput source="cpMob5" label="Remarks 1" formClassName={classes.two_three_input} />
          <TextInput source="cpRes5" label="Remarks 2"  formClassName={classes.last_three_input} />
          
          
          
        </SimpleForm>

    </Create>
  );
};
