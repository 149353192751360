import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";


const Filters = props => (
    <Filter {...props} variant="outlined">
      <SearchInput variant="outlined" label="Code" source="code~like" alwaysOn />
        <SearchInput variant="outlined" label="Name" source="name~like" alwaysOn />
        <SearchInput variant="outlined" label="Value" source="value~like" alwaysOn />
    </Filter>
);
export const LookupList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport}  filter={ { isModByUser: 1 } }  filters={<Filters />}  >
      <Datagrid rowClick={false} > 
        {propsObj.hasEdit && <EditButton />}
        <TextField source="code" label="Code" />
        <TextField source="name" label="Name" />
        <TextField source="value" label="Value" />
        <TextField source="Narration" label="Narration" />
        <TextField source="value1" label="Value1" />
      </Datagrid>
    </List>
  );
};

