import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, Create, FormDataConsumer,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { FoPaymentfields } from './FoPaymentFields';

const FoPaymentDocumentUpload = props => {
    console.log(props, "===paymentId====");
    const classes = useStyles({});
    const handleSubmit = async (formdata) => {
        //console.log(formdata, "===formdata===");

        let m = moment(new Date());
        let data = formdata;
        if (formdata.taDocUrl) {
            let taDocUrl = await apiClient.UploadFile("taDocUrl", formdata.taDocUrl);
            data.taDocUrl = taDocUrl;
        }
        if (formdata.incDocUrl) {
            let incDocUrl = await apiClient.UploadFile("incDocUrl", formdata.incDocUrl);
            data.incDocUrl = incDocUrl;
        }

        if (formdata.paymentPaidDt) {
            let pd = moment(formdata.paymentPaidDt);
            formdata.paymentPaidDt = pd.toISOString();
        }

        if (!data.createdBy) data.createdBy = 1;
        if (!data.createdDate) data.createdDate = m.toISOString();
        data.modifyBy = 1;
        data.modifydate = m.toISOString();

        Object.keys(data).forEach((item) => {
            if (data[item] == null || data[item] == undefined)
                delete data[item];
        });

        apiClient.updateEntiyRecord("fo-payment-dets", data).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.paymentId} resource="fo-payment-dets" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">

                <FormDataConsumer>
                    {(formDataProps) => (
                        <FoPaymentfields {...formDataProps} />
                    )
                    }
                </FormDataConsumer>

                <FileInput fullWidth={true} source="taDocUrl" label="FO Document">
                    <FileField source="taDocUrl" title="File" />
                </FileInput>

                <FileInput fullWidth={true} source="incDocUrl" label="Bank Payment advice">
                    <FileField source="incDocUrl" title="File" />
                </FileInput>

                {/* <TextInput source="slno" label="SL No" fullWidth={ true } />
                <TextInput source="docName" label="Document name" fullWidth={ true } /> */}

            </SimpleForm>
        </Edit>
    );
}
export default FoPaymentDocumentUpload;