import { Button } from '@material-ui/core';
import React from 'react';
import { List, Datagrid, BooleanField, TextField, EditButton, ReferenceField, DateField, NumberField,useRedirect } from 'react-admin';

import { DisplayDate } from '../../common/DisplayDate';
  
export const CustomList = props => {
    const redirect = useRedirect();
    let propsObj = { ...props };
    let employeeId: any
    employeeId = localStorage.getItem("EmployeeId");
    if (employeeId) {
        employeeId = parseInt(employeeId);
    }
    if (propsObj.permissions) {
        let resourcename = `/${props.resource}`;
        let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
        if (myPermissions.length > 0) {
            let p = myPermissions[0];
            propsObj.hasList = (p.View == 1) ? true : false;
            propsObj.hasShow = (p.View == 1) ? true : false;
            propsObj.hasEdit = (p.Edit == 1) ? true : false;
            propsObj.hasDelete = (p.Delete == 1) ? true : false;
            propsObj.hasCreate = (p.Create == 1) ? true : false;
            propsObj.hasExport = (p.Export == 1) ? true : false;
        }
    }
    const CustomEditButton = (props) => {
        let { record } = props;
        let leavebutton:any;
        leavebutton="";
        if(!record.isApproved)
        {
            leavebutton=<Button onClick={()=>redirect(`employee-attendance-regularizes/${record.id}`)} >Edit</Button>
        } 
        return leavebutton;
      }
    
    return (
        <React.Fragment>
            <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="List of Attendance Regularization " {...props} filter={{ employeeId: employeeId }}>
                <Datagrid rowClick={false}> 
                    {propsObj.hasEdit &&  <CustomEditButton label="" source="id"/>}
                    <ReferenceField source="employeeId" reference="employees" label="Employee" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                     
                    <DisplayDate source="attendanceDate" label="Attedance Date" locales="fr-FR" />
                    <ReferenceField source="attendanceTypeId" reference="lookups" label="Attedance Type" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                    
                    
                    <DateField label="Approved Date" source="approvedDate"  locales="fr-FR" />
                    
                    <TextField source="approverRemarks" label='Approver Remarks' fullWidth={true}/>   
                    <BooleanField source="isApproved" label="Is Approved"/>
                    <ReferenceField source="approverId" reference="users" label="Approver" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                   <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
                    <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
                        <TextField source="userName" />
                    </ReferenceField>
                    <DisplayDate source="modifyDate" label="Updated Date" locales="fr-FR" />  
                </Datagrid>
            </List>
        </React.Fragment>
    );
}