import React, { useState } from 'react';

import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    NumberField,
    BooleanField,
    EditButton,ShowButton,
    DeleteButton,
    ImageField,
    Filter,TopToolbar,CreateButton, ExportButton,
    ReferenceInput,
    SearchInput,
    SelectField, TextInput
  } from "react-admin";
import { DisplayDate } from '../../common/DisplayDate';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import PaymentDetails from './PaymentDetails';
import Button from '@material-ui/core/Button';
import UploadPaymentDetails from './UploadPaymentDetails';

const FoPaymentButton = (props) => {
    return (
        <Button variant="contained"
            onClick={() => {
                props.onClick(props.record.id);
            }}
            color="primary">
            Payment Details
        </Button>
    )
}

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: "80%",
    },
}));


const ListActions = (props) => (
    <TopToolbar>
        {props.hasCreate && <CreateButton {...props} label="Create FO Payment"/>}
        {props.hasExport && <ExportButton {...props}/>}
        {props.hasEdit && <Button onClick={props.onUploadPayment} {...props} >Upload Payment</Button>}
    </TopToolbar>
  );
  
    
export const ActivityList = props => {
    const myclasses = myStyles({});
    const [isDisplayPayments, setIsDisplayPayments] = useState(false);
    const [foPaymentId, setFoPaymentId] = useState(null);


    const onUploadPayment = () => {
        setIsDisplayPayments(true)
    }

    const onCancel = () => {
        setIsDisplayPayments(false)
    }
    return (
        <React.Fragment>
            <List title="List of Fo payments"  sort={{ field: 'id', order: 'DESC' }} {...props}
            actions={<ListActions {...props} onUploadPayment={onUploadPayment} />}
            >
                <Datagrid rowClick={false}>
                  <EditButton/>
                    <TextField source="foPaymentNo" />
                    <DisplayDate source="foPaymentDate" />
                    <DisplayDate source="fromDate" />
                    <DisplayDate source="toDate" />
                </Datagrid>
            </List>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{ paper: myclasses.drawerPaper, }}
                open={isDisplayPayments} >
                {isDisplayPayments && <UploadPaymentDetails
                    onClose={onCancel}
                    onCancel={onCancel}
                    // foPaymentId={foPaymentId}
                    {...props} />}
            </Drawer>
        </React.Fragment>
    );
}