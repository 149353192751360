import React, { useState, useCallback, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { useForm } from 'react-final-form';

export const HospitalAddress = ({ formData, ...rest }) => {
    const form = useForm();
    const [insurer, setInsurer] = useState(null);
    useEffect(() => {
        if(!formData.hospId) return;
        apiClient.getHospitalData(formData.hospId).then(res => {
            setInsurer(res);
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
    },[]);
    return (
        <div className="insurer-address-field-parent">
              {insurer && <div>{ insurer.add1 } { insurer.add2 } { insurer.add3 } { insurer.city } { insurer.state } { insurer.pincode }
              <br/>{insurer.email} | {insurer.phoffice} , {insurer.phmobile}
            </div> }
        </div>
    )
}
