import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,AutocompleteInput,
    email,
    choices, SelectInput,
    Create, SimpleForm, TextInput, DateInput, ReferenceInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const PolicyMailConfigCreate = props => (
    <Create {...props} title="Policy Mail Config">
        <SimpleForm redirect="list"
            variant="outlined">
            <TextInput validate={required()} source="fromMailId" label="From Mail Id" fullWidth={true} />
            <ReferenceInput source="insurerId" reference="insurers"  perPage={500} sort={{ field: 'name', order: 'ASC' }}>
                <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
            </ReferenceInput>
            <TextInput validate={required()} source="mailSubject" label="Mail Subject" fullWidth={true} />
            <TextInput multiline validate={required()} source="mailBody" label="Mail Body" fullWidth={true} />
        </SimpleForm>
    </Create>
);