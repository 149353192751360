import React, { useCallback, useEffect, useState, useRef } from 'react';

import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import { useStyles } from "../../formStyles";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { toast } from 'react-toastify';
import FileUpload from './FileUpload';
import { Button } from '@material-ui/core';

export const columnsDef = [
    { "headerName": "Claim Fee Amount", "field": "foClaimFeeAmount", resizable: true, },
    { "headerName": "Incentive Amount", "field": "foIncentiveAmount", editable: true, singleClickEdit: true, resizable: true, },
    { "headerName": "TA Amount", "field": "fotaAmount", editable: true, singleClickEdit: true },
    { "headerName": "Other Amount", "field": "foOtherAmount", editable: true, singleClickEdit: true },
    { "headerName": "Total Amount", "field": "foTotalAmount", },
    { "headerName": "Total Paid Amount", "field": "foTotalPaidAmount", editable: true, singleClickEdit: true, resizable: true, },
    { "headerName": "NEFT Details", "field": "neftDetails", editable: true, singleClickEdit: true },
    { "headerName": "Payment Mode", "field": "paymentMode", editable: true, singleClickEdit: true },
    { "headerName": "Is Payment Paid", "field": "paymentPaid", editable: true, singleClickEdit: true },
    { "headerName": "Payment Paid Date", "field": "paymentPaidDt", editable: true, singleClickEdit: true },
    { "headerName": "Payment Remarks", "field": "paymentRemarks", editable: true, singleClickEdit: true },
];


const UploadPaymentDetails = props => {
    const gridRef = useRef<any>();
    const classes = useStyles({});
    const foPaymentId = props.foPaymentId
    const [gridColumns, setGridColumns] = useState<any[]>([]);
    const [csvData, setCsvData] = useState([]);
    const [result, setResult] = useState<any[]>([]);
    const [loading, setLoading] = useState<any>(false);



    const ImportFopayments = () => {
        let user: any = localStorage.getItem("userId");
        if (user) {
            user = parseInt(user);
        }
        let data = {
            userId: user,
            data: csvData

        }
        setLoading(true);



        apiClient.importFOPayments(data).then(res => {
            //console.log(res, "===getFopayments====");
            setResult(res.data);
            let cols: any = [];
            let keys: any;
            keys = Object.keys(res.data[0]);
            for (let index = 0; index < keys.length; index++) {
                const element = keys[index];
                cols.push({ "headerName": element, "field": element, resizable: true, flex: 1, minWidth: 150 })
            }
            setGridColumns(cols);
            setLoading(false);
        }).catch(err => {
            toast.error(err);
            setLoading(false);
        });
    }

    const handleFileUpload = (data) => {
        debugger
        // for (let index = 0; index < data.length; index++) {
        //     const element = data[index];
        //     if (element.productCode == null || element.productCode == "") {
        //         delete data[index]
        //     }

        // }
        setCsvData(data);





    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const onCellValueChanged = (event) => {
        console.log(event);
    }
    useEffect(() => {

        if (!foPaymentId) return;
        //  getFopayments();
    }, [foPaymentId]);
    return (

        <div style={{ padding: 10,paddingBottom:100 }} >
            <h4>Upload Bulk Payments</h4>
            <br />
            <FileUpload onFileUpload={handleFileUpload} />
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 20, marginTop: 20 }} >
                {csvData.length > 0 && <div style={{ marginRight: "10px" }}>
                    <Button variant="contained" color="primary" onClick={ImportFopayments}
                        startIcon={<SaveIcon />}
                    >Import</Button>
                </div>
                }
                <div>
                    <Button
                        variant="contained" color="primary"
                        onClick={props.onClose}
                        startIcon={<CancelIcon />}
                    >Back</Button>
                </div>

            </div>
            {<div
                className="ag-theme-alpine box-shadow"
                style={{
                    padding: '0px 24px',
                    height: 'calc(100vh - 200px)',
                    width: '100%'
                }}
            >
                {loading && <CircularProgress />}
                {!loading && result && result.length > 0 && gridColumns && gridColumns.length > 0 && <AgGridReact
                    ref={gridRef}
                    rowSelection={'single'}
                    onFirstDataRendered={onFirstDataRendered}
                    onCellValueChanged={onCellValueChanged}
                    columnDefs={gridColumns}
                    rowData={result}>
                </AgGridReact>
                }
            </div>}
        </div>
    );
}
export default UploadPaymentDetails;