import React, { useCallback, useState } from "react";

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput, AutocompleteInput,
  SelectInput, DateField, Create,
  FormDataConsumer, ReferenceField, useRefresh,
  DateInput, ReferenceManyField, Datagrid, TextField,
  BooleanInput, TabbedForm, FormTab, DateTimeInput,
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { Drawer } from '@material-ui/core';
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "../realize-order/StateDistrict"
import { useStyles } from "../../formStyles";
import moment from "moment";
import { ToastContainer, toast } from 'react-toastify';
import { RealizationOrderPatmentFields } from "../realize-order/RealizationOrderPatmentFields";
import { RealizationOrderOtherFields } from "../realize-order/RealizationOrderOtherFields";
import { InsurerAddress } from './InsurerAddress';
import { makeStyles } from '@material-ui/core/styles';
import CorporateCreate from '../realize-order/CorporateCreate';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { AddressFields } from "./AddressFields";
import { HospitalAddress } from "./HospitalAddress";
import HospitalCreate from './HospitalCreate';


const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,

  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: 850,
  },
}));


const SectionHeading = (props) => {
  const { caption } = props;
  return (
    <div className="heading">
      <h3>{caption}</h3>
    </div>
  )
}

export const ClaimOrderCreate = (props) => {
  const [isAddCorportate, setIsAddCorportate] = useState(false);
  const [isAddHospital, setIsAddHospital] = useState(false);
  const [isVendorDisplayed, setIsVendorDisplayed] = useState(false);

  const refresh = useRefresh();
  const classes = useStyles({});
  const myclasses = myStyles({});

  const checkVendor = (data: any) => {
    if (data == 112)
      setIsVendorDisplayed(true);
    else
      setIsVendorDisplayed(false);
  }

  let defaultValues = {
    vid: "VID000",
    claimno: "",
    iclaimno: "",
    lobTypeId: 0,
    sourceCatId: 0,
    claimTypeId: 0,
    claimAmt: 0,
    initiateOn: moment().toISOString(),
    initiateAt: "",
    initiatedViaId: 0,
    insurerId: 0,
    tpaid: 0,
    policyNo: "",
    policyStartDate: moment().toISOString(),
    policyEndDate: moment().toISOString(),
    policyTypeId: 0,
    pName: "",
    pAdd1: "",
    pAdd2: "",
    pCountryId: 0,
    pDistrictId: 0,
    pStateId: 0,
    pCityId: 0,
    ppin: 0,
    pAge: "",
    pSex: "",
    pMobNo: "",
    pEmail: "",
    pProposer: "",
    pProposerContNo: "",
    corporateId: 0,
    hospId: 0,
    refName: "",
    refCompany: "",
    refCompanyDesig: "",
    refVia: "",
    refDate: moment().toISOString(),
    isEmpannelled: false,
    doa: moment().toISOString(),
    dod: moment().toISOString(),
    treatDoctor: "",
    treatDoctorMob: "",
    provDiagnosis: "",
    finalDiagnosis: "",
    statusId: 0,
    configId: 0,
    forRework: "",
    reworkBy: 0,
    grossFinding: "",
    recommendationId: 0,
    recommendationUnder: "",
    reason1: "",
    reason2: "",
    reason3: "",
    insurerEmail: "",
    tpaEmail: "",
    isAccepted: false,
    isReallotment1: false,
    reallot1Date: moment().toISOString(),
    reallot1Reason: "",
    isReallotment2: false,
    reallot2Date: moment().toISOString(),
    reallot2Reason: "",
    finalClosureTat: "",
    finalClosureDate: moment().toISOString(),
    createdBy: 0,
    createdDate: moment().toISOString(),
    modifyBy: 0,
    modifyDate: moment().toISOString()
  };
  const handleClose = useCallback(() => {
    setIsAddCorportate(false);
    setIsAddHospital(false);
  }, []);


  return (
    <React.Fragment>
      <Create title="Add Claim Order" {...props} >
        <SimpleForm
          initialValues={defaultValues}
          redirect="edit"
          toolbar={<FormToolbar {...props} showDelete={false} />}
          variant="outlined">

          <SectionHeading caption="POLICY DETAILS" />


          <TextInput disabled={true} source="vid" label="Veritas ID" fullWidth={true} formClassName={classes.one_4_input} />
          <ReferenceInput
            validate={[required("Please select "), minValue(1, "Please select ")]}
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Type of LOB" source="lobTypeId" reference="lookups" formClassName={classes.two_4_input}
            filter={{ type: 'TLOB' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            onChange={(ev) => { checkVendor(ev.target.value); }}
            validate={[required("Please select "), minValue(1, "Please select ")]}
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Category of source" source="sourceCatId" reference="lookups" formClassName={classes.three_4_input}
            filter={{ type: 'SRC' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            validate={[required("Please select "), minValue(1, "Please select ")]}
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Type of Claim" source="claimTypeId" reference="lookups" formClassName={classes.last_4_input}
            filter={{ type: 'TCLM' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>

          {isVendorDisplayed && <ReferenceInput validate={[required()]}
            perPage={25} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            label="Vendor Name" source="vendorId" reference="vendors" fullWidth={true} formClassName={classes.one_4_input}>
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>}
          {isVendorDisplayed && <div className="divider"></div>}

          <TextInput
            validate={[required("Please select ")]}
            source="claimno" label="TPA Claim No" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput validate={[required()]} source="iclaimno" label="Insurance Claim No" fullWidth={true} formClassName={classes.two_4_input} />

          <DateInput validate={[required("Please select ")]} label="Initiate On" source="initiateOn" fullWidth={true} formClassName={classes.three_4_input} />
          <DateInput type="time" label="Initiate At" source="initiateAt" fullWidth={true} formClassName={classes.last_4_input} />

          <div className="input-divider"></div>
          <ReferenceInput
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Intimated Via" source="initiatedViaId" reference="lookups" formClassName={classes.one_three_input}
            filter={{ type: 'INVIA' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>


          <ReferenceInput
            perPage={25} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} formClassName={classes.two_three_input}>
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>

          <ReferenceInput

            perPage={25} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            label="TPA Name" source="tpaid" reference="tpas" fullWidth={true} formClassName={classes.last_three_input}>
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput>
          <FormDataConsumer>
            {formDataProps => (
              <div className="insurer-address"><InsurerAddress {...formDataProps} /></div>
            )}
          </FormDataConsumer>
          <TextInput source="insurerEmail" label="Insurer Email" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput source="tpaEmail" label=" TPAEmail" fullWidth={true} formClassName={classes.two_4_input} />
          <NumberInput validate={[required(), minValue(1)]} label="Claim Amount" source="claimAmt" fullWidth={true} formClassName={classes.three_4_input} />
          <ReferenceInput
            validate={[required(), minValue(1)]}
            perPage={500} sort={{ field: 'name', order: 'ASC' }}
            label="Type of Policy" source="policyTypeId" reference="lookups" formClassName={classes.last_4_input}
            filter={{ type: 'TPLC' }} fullWidth={true} >
            <SelectInput optionText="name" />
          </ReferenceInput>

          <TextInput validate={[required()]} source="policyNo" label="Policy No" fullWidth={true} formClassName={classes.one_4_input} />
          <DateInput validate={[required()]} label="Policy Start Date" source="policyStartDate" fullWidth={true} formClassName={classes.two_4_input} />
          <DateInput validate={[required()]} label="Policy End Date" source="policyEndDate" fullWidth={true} formClassName={classes.three_4_input} />

          <FormDataConsumer formClassName={classes.last_4_input}>
            {formDataProps => {
              let pType = formDataProps.formData.policyTypeId;
              return (
                <div style={{ display: "flex", flex: "rows" }}>
                  {pType == 61 && <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                    validate={[required(), minValue(1)]}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_4_input}>
                    <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                  </ReferenceInput>}
                  {pType == 61 && <Button onClick={() => { setIsAddCorportate(true) }}   ><AddIcon /></Button>}
                </div>
              )
            }}
          </FormDataConsumer>

          <SectionHeading caption="INSURED DETAILS" />

          <TextInput validate={[required(), minLength(3)]} source="pName" label="Patient Name" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput validate={[required(), minLength(3)]} source="pMobNo" label="Mobile  Number" fullWidth={true} formClassName={classes.two_4_input} />
          <TextInput source="pEmail" label="Patient e-Mail" fullWidth={true} formClassName={classes.three_4_input} />
          <TextInput source="pAge" label="Patient Age" fullWidth={true} formClassName={classes.last_4_input} />

          <SelectInput label="Patient Sex" source="pSex" fullWidth={true} formClassName={classes.one_4_input}
            choices={[
              { id: 'Male', name: 'Male' },
              { id: 'Female', name: 'Female' },
              { id: 'Other', name: 'Other' }
            ]} />

          <TextInput source="pProposer" label="Proposer Name" fullWidth={true} formClassName={classes.twice_4_input} />
          <TextInput source="pProposerContNo" label="Proposer Contact no." fullWidth={true} formClassName={classes.three_4_input} />

          <div className="input-divider"></div>

          <TextInput validate={[required()]} source="pAdd1" label="Address 1" fullWidth={true} formClassName={classes.address_1} />
          <TextInput source="pAdd2" label="Address 2" fullWidth={true} formClassName={classes.address_2} />
          <FormDataConsumer>
            {formDataProps => (
              <AddressFields {...formDataProps} />
            )}
          </FormDataConsumer>
          {/* <NumberInput validate={[required(), minValue(1)]} source="ppin" label="Zipcode" fullWidth={true} formClassName={classes.one_4_input} /> */}

          <SectionHeading caption="HOSPITAL DETAILS" />

          <FormDataConsumer formClassName={classes.one_4_input}>
            {formDataProps => {
              return (
                <div style={{ display: "flex", flex: "rows" }}>
                  {<ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                    validate={[required(), minValue(1)]}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Hospital Name" source="hospId" reference="hospitals" fullWidth={true} formClassName={classes.one_4_input}>
                    <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                  </ReferenceInput>}
                  {<Button onClick={() => { setIsAddHospital(true) }}   ><AddIcon /></Button>}
                </div>
              )
            }}
          </FormDataConsumer>

          {/* <ReferenceInput
          validate={[required(), minValue(1)]}
            perPage={25} sort={{ field: 'name', order: 'ASC' }}
            filterToQuery={searchText => ({ 'name~like': searchText })}
            label="Hospital Name" source="hospId" reference="hospitals" fullWidth={true} formClassName={classes.twice_4_input}>
            <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
          </ReferenceInput> */}

          <FormDataConsumer formClassName="address-field">
            {formDataProps => (
              <div className="hospital-address"><HospitalAddress {...formDataProps} /></div>
            )}
          </FormDataConsumer>


          <BooleanInput label="Empanelled with HDC" source="isEmpannelled" fullWidth={true} formClassName={classes.one_4_input} />
          <BooleanInput label="Investgation allotment accepted by Veritas" source="isAccepted" fullWidth={true} formClassName={classes.twice_4_input} />
          <div className="input-divider"></div>


          <DateInput label="Date of admission" source="doa" fullWidth={true} formClassName={classes.one_4_input} />
          <DateInput label="Date of discharge" source="dod" fullWidth={true} formClassName={classes.two_4_input} />

          <TextInput source="treatDoctor" label="Treating Doctor Name" fullWidth={true} formClassName={classes.three_4_input} />
          <TextInput source="treatDoctorMob" label="Treating Doctor Contact No." fullWidth={true} formClassName={classes.last_4_input} />

          <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="provDiagnosis" label="Provisional Diagnosis" fullWidth={true} formClassName={classes.first_inline_input} />
          <RichTextInput toolbar={[['bold', 'italic', 'underline']]} source="finalDiagnosis" label="Final Diagnosis" fullWidth={true} formClassName={classes.last_inline_input} />

          <SectionHeading caption="Recommendation, if any" />


          <TextInput source="refName" label="Reference Name" fullWidth={true} formClassName={classes.one_4_input} />
          <TextInput source="refCompany" label="Reference Company" fullWidth={true} formClassName={classes.two_4_input} />
          <TextInput source="refCompanyDesig" label="Reference Designation" fullWidth={true} formClassName={classes.three_4_input} />
          <TextInput source="refVia" label="Reference Via" fullWidth={true} formClassName={classes.last_4_input} />


          <DateInput label="Reference Date" source="refDate" fullWidth={true} formClassName={classes.one_4_input} />
          <div></div>
          <BooleanInput source="isReportPrepared"  variant="outlined" label="Report Prepared" fullWidth={ true } formClassName={ classes.one_4_input } />
      <DateInput label="Report Prepared Date"  variant="outlined"  source="reportPreparedDate"   fullWidth={ true } formClassName={classes.two_4_input  } /> 
      
      <ReferenceInput
                    perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
                    filterToQuery={ searchText => ({ 'name~like': searchText }) }
                    variant="outlined"  label="Report Prepared By" source="reportPreparedBy" reference="employees"fullWidth={ true } formClassName={classes.three_4_input  } 
                    >
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
                </ReferenceInput>
          <DateInput label="Document Received On"  variant="outlined"  source="documentReceivedOn"   fullWidth={ true } formClassName={classes.two_4_input  } />   

        </SimpleForm>
      </Create>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isAddCorportate || isAddHospital} >
        {isAddCorportate && <CorporateCreate onClose={handleClose} />}
        {isAddHospital && <HospitalCreate onClose={handleClose} />}

      </Drawer>
    </React.Fragment>

  );
};
