import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,SelectInput,
    Create, SimpleForm, TextInput, DateInput,ReferenceInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const CityCreate = props => (
    <Create {...props} title="City">
        <SimpleForm redirect="list" defaultValue={{ citytypeid: 1 }}
        variant="outlined">
            <TextInput validate={required()} source="code" label="City Code" fullWidth={true} />
            <TextInput validate={required()} source="name" label="City Name" fullWidth={true} />
            <ReferenceInput source="districtId" reference="districts">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput initialValue={true} source="isActive" />
        </SimpleForm>
    </Create>
);