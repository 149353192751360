import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField,Filter,TextInput
}
    from 'react-admin';
    
const Filters = props => (
    <Filter {...props} variant="outlined" className="filter-block">
      <TextInput variant="outlined" label="District" source="name~like" alwaysOn /> 
    </Filter>
  );
export const DistrictList = props => (
    <List {...props} title="District"  filters={<Filters />}>
        <Datagrid rowClick="show">
            <EditButton />
            <TextField source="code" label="District Code" />
            <TextField source="name" label="District Name" />
            <ReferenceField source="stateId" reference="states" label="States"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" label="Is Active" />
        </Datagrid>
    </List>
);