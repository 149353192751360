import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";
import city from '../city';

export const AddressFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    return (
        <div className="address-field-parent2">
            <ReferenceInput variant="outlined" label="Country" source="pCountryId" reference="countries"
                onChange={(v) => {
                    console.log(v);
                    form.change("pStateId", 0);
                    form.change("pCityId", 0);
                    form.change("pDistrictId", 0);
                    form.change("zipCodeId", 0);
                }}
                validate={required()} 
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} >
                <AutocompleteInput optionText="name" />

            </ReferenceInput>
            {/* <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'name~like': searchText })}
                    label="Corporate Name" source="corporateId" reference="corporates" fullWidth={true} formClassName={classes.last_inline_input}>
                    <AutocompleteInput optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                  </ReferenceInput> */}

            <ReferenceInput variant="outlined" label="State" source="pStateId"
                reference="states" perPage={500}
                filter={{countryId: formData.pCountryId}}
                onChange={(v) => {
                    //console.log(v);
                    form.change("pCityId", 0);
                    form.change("pDistrictId", 0);
                    form.change("zipCodeId", 0);
                }}
                validate={required()} 
                sort={{ field: 'name', order: 'ASC' }}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} >
                {/* <SelectInput validate={required()} optionText="name" /> */}
                <AutocompleteInput validate={required()} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  fullWidth={true} className={classes.two_4_input}/>
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="District" source="pDistrictId"
                reference="districts" perPage={500}
                filter={{stateId: formData.pStateId}}
                onChange={(v) => {
                    //console.log(v);
                    form.change("pCityId", 0);
                    form.change("zipCodeId", 0);
                    
                }}
                validate={required()} 
                sort={{ field: 'name', order: 'ASC' }}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} >
                {/* <SelectInput validate={required()} optionText="name" /> */}
                <AutocompleteInput validate={required()} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  fullWidth={true} className={classes.two_4_input}/>
            </ReferenceInput>


            <ReferenceInput variant="outlined" label="City"
              filter={{districtId: formData.pDistrictId}}
              sort={{ field: 'name', order: 'ASC' }}
                validate={required()} source="pCityId" reference="cities" perPage={500}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} >
                {/* <SelectInput  validate={required()} optionText="name" /> */}
                <AutocompleteInput validate={required()} optionText="name" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  fullWidth={true} className={classes.two_4_input}/>
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="zipCode"
              filter={{cityId: formData.pCityId!=null?formData.pCityId:0}}
              sort={{ field: 'zipcode', order: 'ASC' }}
                validate={required()} source="zipCodeId" reference="zipcodes" perPage={500}
                shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}
                fullWidth={true} >
                {/* <SelectInput  validate={required()} optionText="zipCode" /> */}
                <AutocompleteInput validate={required()} optionText="zipCode" variant="outlined" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }}  fullWidth={true} className={classes.two_4_input}/>
            </ReferenceInput>
            
        </div>
    )
}
