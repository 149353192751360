import React, { useState, useCallback } from 'react';
import Button from '@material-ui/core/Button'  
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import HospitalPrint from "./HospitalPrint";
const myStyles = makeStyles(theme => ({
  root: {
      display: 'flex',
  },
  list: {
      flexGrow: 1,
      transition: theme.transitions.create(['all'], {
          duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
  },
  listWithDrawer: {
      marginRight: 300,
  },
  drawerPaper: {
      zIndex: 100,
      marginTop: 50,
      marginRight: 5,
      
      width: 650,
  },
}));
const Filters = props => (
  <Filter {...props} variant="outlined">
  <SearchInput variant="outlined" label="Hospital Code" source="code~like" alwaysOn />
  <SearchInput variant="outlined" label="Hospital Name" source="name~like" alwaysOn />
  <ReferenceInput label="Area" source="areaId" reference="areas" >
        <SelectInput optionText="name" />
    </ReferenceInput>
</Filter>
);
export const HospitalList = (props) => {
  const [isPrint, setIsPrint] = useState(false);
  const [hospitalid, sethospitalId] = useState(0);
  
  const myclasses = myStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsPrint(false)
  }
  

const PrintButton=(props)=>(
  
  <Button onClick={props.click}  > Print  </Button>
)
  
  const onCancel = () => {
    setIsPrint(false)
  }
  const handleClose = useCallback(() => {
    //setIsAddTpa(false);
}, []);
function showPopup(id){
    
  sethospitalId(id)
  setIsPrint(true);
}
const DisplayFileLink = (props) => {
  let { record } = props;

  return (
    <PrintButton click={(e)=>showPopup(record.id)}   />
    
  )
}
  return (
    
    <React.Fragment>
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Hospital List" filters={<Filters />}  >
      <Datagrid rowClick={ false}>
        {propsObj.hasEdit && <EditButton />}
        <DisplayFileLink label="" source="id" />
        <TextField source="code" label="Hospital Code" />
        <TextField source="name" label="Hospital Name" />
        <ReferenceField source="areaId" reference="areas" link={false} label="Area" >
                        <TextField source="name" />
                    </ReferenceField>
      
      <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR"/>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date" locales="fr-FR"/>
            </Datagrid>      
    </List>
      <Drawer
      anchor="right"
      onClose={handleClose}
      classes={{ paper: myclasses.drawerPaper, }}
      open={isPrint}>
        { isPrint && <HospitalPrint
                        onClose={closeEdit}
                        onCancel={onCancel}
                        hospitalId={hospitalid}
                        {...props} />}
        </Drawer>
      </React.Fragment>
  );
};
