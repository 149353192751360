
import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,ReferenceField,
    BooleanField, EditButton, DeleteButton, ImageField,TextInput,Filter
} from 'react-admin';
const Filters = props => (
    <Filter {...props} variant="outlined" className="filter-block">
      <TextInput variant="outlined" label="City" source="name~like" alwaysOn /> 
    </Filter>
  );
export const CityList = props => (
    <List {...props} title="City" filters={<Filters />}>
        <Datagrid rowClick={false}>
            <EditButton />
            <TextField source="code" label="City Code" />
            <TextField source="name" label="City Name" />
            <ReferenceField source="districtId" reference="districts" label="District"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            {/* <ReferenceField source="stateid" reference="states" label="States"  link={false}>
                <TextField source="statename" />
            </ReferenceField> */}
            <BooleanField source="isActive" label="Is Active" />
        </Datagrid>
    </List>
);