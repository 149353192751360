import React from "react";
import {
  useNotify,
  useRedirect,
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  NumberInput,
  ReferenceInput,
  DateInput,
  required,
  BooleanInput
} from "react-admin";
import * as apiClient from "../../common/apiClient";
import { FormToolbar } from "../../common/FormToolbar";
import moment from "moment";
export const CustomCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  let employeeId: any
  employeeId = localStorage.getItem("EmployeeId");
  if (employeeId) {
    employeeId = parseInt(employeeId);
  }
  let approverId: any
  approverId = localStorage.getItem("ApproverId");
  if (approverId) {
    approverId = parseInt(approverId);
  }
  const initialValues = {
    employeeId: employeeId,
    approverId: approverId,
    isApproved: false,
    isReject: false,
    approverRemarks: "",
    isActive: true,
    remarks: "",
    

  }
  const handleSubmit = (formdata) => {
    let userId: any
    userId = localStorage.getItem("userId");
    if (userId) {
      userId = parseInt(userId);
    }
    formdata.createdBy = userId;
    formdata.createdDate = new Date();
    
    formdata.approvedDate = formdata.attendanceDate
    

    if (new Date(formdata.attendanceDate) > new Date()) {
      notify("You cannot apply future date", "warning")
      return;
    }
    let m = moment(formdata.attendanceDate);
    formdata.attendanceDate = m.toISOString();
    m = moment(formdata.approvedDate );
    formdata.approvedDate =m.toISOString();
    apiClient.addEntiyRecord("employee-attendance-regularizes", formdata).then(res => {
      notify("Attendance Regularization  is created")
      redirect("/employee-attendance-regularizes");
    }).catch(err => {
      console.log(err);
    });
    

  }
  
  return (
    <Create title="Attendance Regularization Create" {...props}>
      <SimpleForm onSubmit={handleSubmit} initialValues={initialValues} redirect="list"   toolbar={<FormToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />} variant="outlined" >
        
        <DateInput variant="outlined" label="Attendance Date" source="attendanceDate" fullWidth={true} validate={[required()]} /> 
        <ReferenceInput label="Type"  fullWidth={true}   
                    perPage={5000} source="attendanceTypeId"
                    filter={{ type: 'RegLt' }}

                    sort={{ field: 'name', order: 'ASC' }}
                    reference="lookups" >
                    <SelectInput optionText="name" variant="outlined"   />
                </ReferenceInput>
        <SelectInput label="Location" source="location" fullWidth={true} 
              choices={[
                { id: 'WFH', name: 'WFH' },
                { id: 'WFO', name: 'WFO' },
                { id: 'WFF', name: 'WFF' }
              ]} /> 
        
        <TextInput source="remarks" label='Remarks' fullWidth={true} />  
      </SimpleForm>
    </Create>
  );
};
