import React, { useState } from 'react';
import {
    Edit, SimpleForm,
    TextInput, ReferenceInput, useRefresh,
    SelectInput, required, DateInput, ReferenceManyField,
    TabbedForm, FormTab, Datagrid, ReferenceField, BooleanField,
    AutocompleteInput, TextField
} from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import VSDeleteButton from '../../VSDeleteButton';
import PaymentCreate from './PaymentCreate';
import FoPaymentDocumentUpload from './FoPaymentDocumentUpload';
import { useStyles } from "../../formStyles";
import { DisplayDate } from '../../common/DisplayDate';

const DisplayFileLink = (props) => {
    let { record, source } = props;
    if (source && record[source] && record[source].length > 0)
        return (
            <a href={record[source]}>View File</a>
        )
    else {
        return null
    }
}

const FoEditPaymentButton = (props) => {
    return (
        <Button variant="contained"
            onClick={() => {
                props.onClick(props.record.id);
            }}
            color="primary">
            Edit Docs
        </Button>
    )
}

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 600,
    },
}));


export const ActivityEdit = props => {
    const myclasses = myStyles({});
    const classes = useStyles({});
    const refresh = useRefresh();
    const [isEditPayments, setIsEditPayment] = useState(false);
    const [isAddPayment, setIsAddPayment] = useState(false);
    const [paymentId, setpaymentId] = useState(null);

    let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

    const onClose = () => {
        setIsEditPayment(false);
        setIsAddPayment(false);
        refresh();
    }


    const onCancel = () => {
        setIsEditPayment(false);
        setIsAddPayment(false);
    }
    return (
        <React.Fragment>
            <Edit title="Edit FO Payment" {...propsObj}>
                <TabbedForm toolbar={<FormToolbar {...propsObj} />}
                    variant="outlined">
                    <FormTab label="Basic Details">
                        <TextInput source="foPaymentNo" fullWidth={true} formClassName={classes.first_inline_input} validate={[required()]} />
                        <DateInput source="foPaymentDate" fullWidth={true} formClassName={classes.last_inline_input} validate={[required()]} />
                        <DateInput source="fromDate" fullWidth={true} formClassName={classes.first_inline_input} validate={[required()]} />
                        <DateInput source="toDate" fullWidth={true} formClassName={classes.last_inline_input} validate={[required()]} />
                    </FormTab>
                    <FormTab label="Payment Details">
                        {/* <Button onClick={() => { setIsAddPayment(true); }}
                            color="secondary">
                            Add Payment
                        </Button> */}
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="fo-payment-dets"
                            target="foPaymentId">
                            <Datagrid>
                                {propsObj.hasDelete && <VSDeleteButton {...props} />}
                                <FoEditPaymentButton {...props} onClick={(id) => {
                                    setpaymentId(id);
                                    setIsEditPayment(true);
                                }} />
                                <ReferenceField link={false} label="FO Name" source="employeeId" reference="employees" fullWidth={true} >
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField link={false} label="Vid" source="claimOrdId" reference="claim-ords" fullWidth={true} >
                                    <TextField source="vid" />
                                </ReferenceField>
                                <ReferenceField link={false} label="Claim No" source="claimOrdId" reference="claim-ords" fullWidth={true} >
                                    <TextField source="claimno" />
                                </ReferenceField>
                                <ReferenceField link={false} label="Iclaim No" source="claimOrdId" reference="claim-ords" fullWidth={true} >
                                    <TextField source="iclaimno" />
                                </ReferenceField>
                                
na
                                <ReferenceField link={false} label="Patient Name" source="claimOrdId" reference="claim-ords" fullWidth={true} >
                                    <TextField source="pName" />
                                </ReferenceField>
                                <TextField source="foClaimFeeAmount" label="Claim Fee Amount" />
                                <TextField source="foIncentiveAmount" label="Incentive Amount" />
                                <TextField source="fotaAmount" label="Billing Amount" />
                                <TextField source="foTotalAmount" label="Total Amount" />
                                <TextField source="foTotalPaidAmount" label="Total Paid Amount" />
                                <TextField source="neftDetails" label="NEFT Details" />
                                <TextField source="paymentMode" label="Payment Mode" />
                                <BooleanField source="paymentPaid" label="Payment Paid" />
                                <DisplayDate source="paymentPaidDt" label="Payment Paid Date" />
                                <TextField source="paymentRemarks" label="Payment Remarks" />
                                <DisplayFileLink label="FO Document" source="taDocUrl" />
                                <DisplayFileLink label="Bank Payment advice" source="incDocUrl" />

                            </Datagrid>
                        </ReferenceManyField>
                    </FormTab>
                </TabbedForm>
            </Edit>
            <Drawer
                anchor="right"
                onClose={onCancel}
                classes={{ paper: myclasses.drawerPaper, }}
                open={isEditPayments || isAddPayment} >
                {isEditPayments && <FoPaymentDocumentUpload
                    onClose={onClose}
                    onCancel={onCancel}
                    paymentId={paymentId}
                    {...props} />}

            </Drawer>
        </React.Fragment>
    );
}