import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  FormTab
} from "react-admin";
import { CreateToolbar } from "../../CreateToolbar";
import { useStyles } from "../../formStyles";
export const VendorEdit = (props) => {
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <Edit title="Vendor Edit" {...propsObj}>

      <SimpleForm variant="outlined" redirect="list" toolbar={<CreateToolbar {...props} />}>

        <TextInput source="code" label="Code" formClassName={classes.one_three_input} disabled={true} />
        <TextInput source="name" label="Name" validate={[required()]} formClassName={classes.two_three_input} />
        <TextInput source="phoffice" label="Phone(Off)." formClassName={classes.last_three_input} />

        <TextInput source="email" label="email" formClassName={classes.one_three_input} />
        <TextInput source="phmobile" label="Mobile" formClassName={classes.two_three_input} />
        <TextInput source="cstNo" label="CST Number" formClassName={classes.last_three_input} />

        <TextInput source="tpt" label="tpt" formClassName={classes.one_three_input} />
        <TextInput source="licno" label="LIC No" formClassName={classes.two_three_input} />
        <TextInput source="panno" label="Pan Number" formClassName={classes.last_three_input} />

        <TextInput source="state" label="State" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="city" label="City" validate={[required()]} formClassName={classes.two_three_input} />
        <TextInput source="add1" label="Address 1" validate={[required()]} formClassName={classes.last_three_input} />

        <TextInput source="add2" label="Address 2" formClassName={classes.one_three_input} />
        <TextInput source="add3" label="Address 3" formClassName={classes.two_three_input} />
        <TextInput source="pincode" label="Pincode" validate={[required()]} formClassName={classes.last_three_input} />

        <TextInput source="cpName1" label="Contact Person 1" formClassName={classes.one_three_input} />
        <TextInput source="cpMob1" label="Contact Person 1 Mobile" formClassName={classes.two_three_input} />
        <TextInput source="cpRes1" label="Contact Person 1 ResiID" formClassName={classes.last_three_input} />

        <TextInput source="cpName2" label="Contact Person 2" formClassName={classes.one_three_input} />
        <TextInput source="cpMob2" label="Contact Person 2 Mobile" formClassName={classes.two_three_input} />
        <TextInput source="cpRes2" label="Contact Person 2 ResiID" formClassName={classes.last_three_input} />

        <TextInput source="cpName3" label="Contact Person 3" formClassName={classes.one_three_input} />
        <TextInput source="cpMob3" label="Contact Person 3 Mobile" formClassName={classes.two_three_input} />
        <TextInput source="cpRes3" label="Contact Person 3 ResiID" formClassName={classes.last_three_input} />


        <TextInput source="cpName4" label="Contact Person 4" formClassName={classes.one_three_input} />
        <TextInput source="cpMob4" label="Contact Person 4 Mobile" formClassName={classes.two_three_input} />
        <TextInput source="cpRes4" label="Contact Person 4 ResiID" formClassName={classes.last_three_input} />

        <TextInput source="cpName5" label="Contact Person 5" formClassName={classes.one_three_input} />
        <TextInput source="cpMob5" label="Contact Person 5 Mobile" formClassName={classes.two_three_input} />
        <TextInput source="cpRes5" label="Contact Person 5 ResiID" formClassName={classes.last_three_input} />


        <BooleanInput source="isActive" label="Active" initialValue={true} formClassName={classes.one_three_input} />

      </SimpleForm>


    </Edit>
  );
};
