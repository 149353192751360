import React, { useState, useCallback, useEffect } from 'react';
import { PDFViewer, Document, Page, Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import * as apiClient from "../../../common/apiClient";
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import logo from '../../../images/logo.png'
import sign from '../../../images/print3Sign.png'


const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingHorizontal: 5,
    paddingRight: 15,
    paddingLeft: 30,
    fontSize: 10,

  },
  tableHeader: {
    fontSize: 11,
    height: 20,
    textAlign: 'left',
    fontWeight: "bold"

  },
  tableRow: {
    fontSize: 10,
    height: 20,
    textAlign: 'left',


  },
  headercontainer: {

    flexDirection: "row",
  },
  headercontainer1: {
    height: 50,
    flexDirection: "row",
  },
  logocontainer: {
    height: 50,
    width: "20%",

  },
  logocontainer1: {
    height: 0,
    width: "0%",

  },
  companyaddress: {
    height: 100,
    width: "80%",
    textAlign: 'left',
    fontSize: 10,
    marginLeft: 30,

  },
  companyaddress1: {
    height: 50,
    width: "100%",
    textAlign: 'center',
    fontSize: 10,
    marginLeft: 10,

  },
  invoiceHeader: {
    fontSize: 12,
    marginTop:20,
    textAlign: 'center',
    fontWeight: "bold",
    // borderTop: 1,
    // borderLeft: 1,
    // borderRight: 1,
    flexDirection: "column",
   textDecoration:'underline'

  },

  invoiceHeader1: {
    fontSize: 15,
    
    textAlign: 'center',
    fontWeight: "bold",
    // borderTop: 1,
    // borderLeft: 1,
    // borderRight: 1,
    flexDirection: "column",
   

  },
  headerRow: {
    marginTop: 8,
    flexDirection: "row",

  },
  partyBox:
  {
    flexDirection: "row",
    //height: "100",
    //border: 1,
    paddingLeft: 10,
    fontSize: 11
  },
  partyBoxAddress:
  {
    flexDirection: "column",
    height: "100",
    width: "70%",


  },
  partyBoxAddress1:
  {
    height: "70%",
    width: "100%",
    fontSize: 12,
    paddingTop: 10,
  },
  billingMonth:
  {
    height: "30%",
    width: "100%",
    fontSize: 12,
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 10

  },
  invoiceDetail:
  {
    flexDirection: "column",
    height: "100",
    borderLeft: 1,
    width: "30%",
    fontSize: 11
  },
  invoiceDetailbox1:
  {
    height: "20%",
    borderBottom: 1,
    textAlign: "center",
  },
  invoiceDetailbox2:
  {
    height: "20%",
    textAlign: "center",
  },
  invoiceDetailDate:
  {
    height: "30%",
    textAlign: "center",
  },

  tableHeaderStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    height: "5%",
    width: "100%",
    fontSize: 11,
    borderBottom: 1,
    borderLeft: 1

  },
  tableHeaderStylebox1:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableHeaderStylebox2:
  {
    paddingTop: 3,
    textAlign: "center",
    height: "10%",
    width: "70%",

  },

  tableHeaderStylebox3:
  {
    paddingTop: 3,
    textAlign: "center",
    width: "20%",
    borderLeft: 1,
    borderRight: 1
  },
  tableRow:
  {

    flexDirection: "column",
    width: "100%",
    borderBottom: 1,

  },
  tableRowStyle:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: "3%"

  },
  tableRowStylebox1:
  {
    paddingRight: 5,
    paddingTop: 5,
    textAlign: "center",
    width: "10%",
    borderRight: 1
  },

  tableRowStylebox2:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "left",
    width: "70%",

  },
  tableRowStylebox3:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    paddingRight: 3,
    paddingTop: 5
  },
  tableRowStylebox2Total:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox3TotalAmount:
  {
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    paddingRight: 3,
    paddingTop: 5,
    fontSize: 13

  },
  tableRowStylebox2Tax:
  {
    flexDirection: "row",
    paddingRight: 0,
    paddingTop: 0,
    paddingLeft: 0,
    textAlign: "right",
    width: "70%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2TaxEmptyBox:
  {
    paddingRight: 5,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 13

  },
  tableRowStylebox2Taxdetail:
  {
    paddingRight: 3,
    paddingTop: 5,
    paddingLeft: 3,
    textAlign: "right",
    width: "50%",
    fontWeight: "bold",
    fontSize: 11,
    borderLeft: 1,
    borderRight: 0,
    borderTop: 1,
    borderBottom: 1,


  },
  tableRowStylebox3Taxamount:
  {

    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    paddingRight: 3,
    paddingTop: 50
  },
  tableRowStylewith5height:
  {

    flexDirection: "row",
    textAlign: "center",
    width: "100%",
    fontSize: 11,
    borderBottom: 0,
    borderLeft: 1,
    minHeight: 5

  },
  tableHeaderStylebox2GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    height: "10%",
    width: "70%",
    fontSize: 13

  },
  tableHeaderStylebox3GrandTotal:
  {
    paddingTop: 3,
    paddingRight: 3,
    textAlign: "right",
    width: "20%",
    borderLeft: 1,
    borderRight: 1,
    fontSize: 13
  },
  boxAmountinWords:
  {
    flexDirection: "row",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    height: 30,
    fontSize: 11
  },
  bankDetailBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 30,
    fontSize: 11
  },
  bankAccountDetailBox:
  {
    flexDirection: "row",

    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 15,
    fontSize: 11
  },
  bankAccountDetailBox1:
  {

    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "35%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox2:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox3:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "25%",
    borderRight: 1,
    borderTop: 0,
    borderTop: 0,
    fontSize: 11
  },
  bankAccountDetailBox4:
  {


    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "center",
    width: "20%",
    borderRight: 1,
    borderTop: 0,
    fontSize: 11
  },
  signatureBox:
  {
    flexDirection: "column",
    paddingTop: 12,
    paddingRight: 3,
    paddingLeft: 3,
    textAlign: "left",
    width: "100%",
    borderLeft: 1,
    borderRight: 1,
    borderBottom: 1,
    borderTop: 0,
    height: 100,
    fontSize: 11
  },

});


const Print4 = props => {

  const [isLoading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState({ partyName: "RedPlus Society,", address: "Baghanjatin Station Road Kolkata-700032 West Bengal", invoiceNo: "HDFC/20-21/JAN/0392R", invoiceDate: "11.11.2021", billMonth: "Month Of December 2020", taxableAmount: "1000.00" });
  const [detailData, setDetailData] = useState([{ particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims .", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }, { particluars: "Service Charges against the releastion of claims . Detals are enclosed with the inovice.  (Recovered amt-Rs.3,73,251 towards 11 claims)", amount: "14390.00" }]);
  const [footerDetail, setFooterDetail] = useState("");
  const [blankRow, setBlankRow] = useState([]);
  const [footerDetail1, setFooterDetail1] = useState("");
  useEffect(() => { LoadData(props.invoiceId); }, []);
  const LoadData = async (id) => { 
    await apiClient.printLetter(id,'AUTH').then(res => { 
      let data = []; 
      setHeaderData(res[0]);  
      setLoading(false); 
    }).catch(err => {
      setLoading(false);
      console.log(err);
    });  
  }
  

  return (
    <React.Fragment>
      <div>
        <div style={{ float: "right" }} onClick={props.onClose}><CancelSharpIcon /></div>
      </div>
      {isLoading === false ?
        <PDFViewer width="100%" height="600" >
          <Document title="report" >
            <Page size="A4" style={styles.page}>
              <View>
              
                <View style={styles.headercontainer}>
                  <View style={styles.logocontainer}>
                    <Image src={logo} />
                  </View>
                  <View style={styles.companyaddress}>
                    <Text>{headerData.addresses.toString().trim()}</Text>
                    {/* <Text>{headerData.addresses2}</Text>
                <Text>{headerData.addresses3}</Text> */}
                    <Text>{"Phone:" + headerData.Phone}</Text>
                    <Text>{"CIN:" + headerData.CIN}</Text>
                    <Text style={{ marginTop: 5 }}>{"GSTNo:" + headerData.GSTNo}</Text>
                    <Text>{"PANNO:" + headerData.PANNO}</Text>
                    <Text>{"Date :" + headerData.SubmDateTime}</Text>


                  </View>
                  {/* <Image src={logo} /> */}
                </View>

                <View style={styles.invoiceHeader1} >
                  
                  <Text  >{"TO WHOM ITS MAY CONCERN"}</Text>
                  
                  
                </View>
                <View style={styles.invoiceHeader} >
                  
                  <Text>{"Authorization Letter"}</Text>
                  
                  
                </View>
                
                <View style={styles.partyBox} >
                  <View>
               
                    
                   
                    
                    
                    <Text style={{ marginTop: 8 }}>
                    
                    {headerData.Content1} <Text style={{ fontSize: 13,fontWeight:'extrabold' }}>{headerData.Content1_1}</Text> {headerData.Content1_2}
                    <Text style={{ fontSize: 13,fontWeight:'extrabold' }}>{headerData.Insurer_Name}</Text> {headerData.Content1_3}
                    </Text>
                    
                    <Text style={{ marginTop: 8 }}>
                    
                    {headerData.Content2}<Text style={{ fontSize: 13,fontWeight:'extrabold' }}>{headerData.FO_Name}</Text> 
                    {headerData.Content2_1}
                    <Text style={{ fontSize: 13,fontWeight:'extrabold' }}>
                      {headerData.Patient_Name}</Text>  
                    </Text>
                    <Text style={{ marginTop: 8 }}>
                    
                    {headerData.Content3}
                    </Text>
                    



                    <View style={{ marginTop: 20 }} ></View>
                    <Text>
                      {headerData.Footer1}
                    </Text>
                    <Text style={{ marginTop: 2 }}>
                    {headerData.Footer2}
                    </Text>
                    <Text style={{ marginTop: 2 }}>
                    {headerData.Footer3}
                    </Text>
                    <Text style={{ marginTop: 1,fontSize:14,fontWeight:'extrabold' }}>
                    {headerData.Footer4}
                    </Text>
                    <View style={{ minHeight: 5 }} ></View>
                    <View style={styles.logocontainer}>
                    <Image src={sign} />
                  </View>
                  <Text style={{ marginTop: 5, fontSize:12,fontWeight:'extrabold' }}>
                  {headerData.Footer5}
                    </Text>
                    <Text style={{ marginTop: 2, }}>
                    {headerData.Footer6}
                    </Text>
                    <Text style={{ marginTop: 2, }}>
                    {headerData.Footer7}
                      </Text>

                    
                  </View>
                </View>



              </View>
            </Page>
          </Document>
        </PDFViewer> : null}
    </React.Fragment>
  );
}

export default Print4;