import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex, AutocompleteInput,
    email,
    choices,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const PolicyMailLogEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} title="Policy Mail Log">
            <SimpleForm redirect="list"
                variant="outlined">
                <TextInput disabled={true} source="fromMailId" label="From Mail Id" fullWidth={true} />
                <TextInput disabled={true} source="mailSubject" label="Mail Subject" fullWidth={true} />
                <TextInput multiline disabled={true} source="mailBody" label="Mail Body" fullWidth={true} />
                <ReferenceInput
                    perPage={500} sort={{ field: 'name', order: 'ASC' }}
                    label="Status" source="statusId" reference="lookups"
                    filter={{ type: 'PML' }} fullWidth={true} >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput multiline source="remarks" label="Remarks" fullWidth={true} />

                <ReferenceInput perPage={25} sort={{ field: 'vid', order: 'ASC' }}
                    filterToQuery={searchText => ({ 'vid~like': searchText })}
                    label="Claim Order" source="linkId" reference="claim-ords" fullWidth={true}>
                    <AutocompleteInput optionText="vid" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                </ReferenceInput>

            </SimpleForm>
        </Edit>
    );
}