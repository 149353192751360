import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";

export const EmployeeEdit = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <Edit title="Employee Edit" {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput source="code"  fullWidth={true} validate={[required()]} />
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <ReferenceInput label="Type" fullWidth={true} source="typeId" filter={{ type: 'ET' }} reference="lookups" >
                       <SelectInput optionText="name" variant="outlined"   />
                    </ReferenceInput>
        <ReferenceInput perPage={2000} label="User" source="userId" reference="users" fullWidth={true} validate={[required()]} sort={{ field: 'userName', order: 'ASC' }}>
          <SelectInput optionText="userName" />
        </ReferenceInput>
        <DateInput source="doj" label="Date of Joining" fullWidth={true} validate={[required()]} />
        <TextInput source="designation" label="Designation" fullWidth={true} />
        <TextInput source="mobileNo" label="Mobile No" fullWidth={true} validate={[required()]} />
        <TextInput source="altMobileNo" label="Alternate Mobile No" fullWidth={true} />
        <TextInput source="email" label="Email Address" fullWidth={true} />
        <TextInput source="add1" label="Address1" fullWidth={true} validate={[required()]} />
        <TextInput source="add2" label="Address2" fullWidth={true} />
        <TextInput source="city" label="City" fullWidth={true} />
        <TextInput source="state" label="State" fullWidth={true} />
        <TextInput source="zip" label="Pincode" fullWidth={true} />
        <TextInput source="pan" label="PAN" fullWidth={true} />
        <TextInput source="adharNo" label="Adhaar No" fullWidth={true} />
        <TextInput source="bankAcNo" label="Bank Account No" fullWidth={true} />
        <TextInput source="bankName" label="Bank Name" fullWidth={true} />
        <TextInput source="ifscCode" label="IFSC Code" fullWidth={true} />
        <DateInput source="dol" label="Date of Leaving" fullWidth={true} />
        <NumberInput source="basicSalary" label="Basic Salary" fullWidth={true} />
        <NumberInput source="monthlyTax" label="Monthly Tax" fullWidth={true} />

        <BooleanInput source="isActive" validate={[required()]} label="Active" />
      </SimpleForm>
    </Edit>
  );
};
