import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter, SelectInput,AutocompleteInput,
  ReferenceInput,
  SearchInput, TextInput
} from "react-admin";
import { DisplayDate } from "../../common/DisplayDate";

const Filters = props => (
  <Filter {...props} variant="outlined" className="filter-block">
    <TextInput variant="outlined" label="CCN No" source="ccnno~like" alwaysOn />
    <TextInput variant="outlined" label="Name" source="pName~like" alwaysOn/>
    <TextInput variant="outlined" label="Mobile Number" source="pMobNo~like" />
    <TextInput variant="outlined" label="Hospital Bill no" source="hBillNo~like" />
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }}  filterToQuery={searchText => ({ 'name~like': searchText })}
          label="Hospital Name" source="hospitalId" reference="hospitals" fullWidth={true}  allowEmpty alwaysOn>
          <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>
    {/* <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name"  shouldRenderSuggestions={(val) => { return val && val.trim().length   >= 1 }} />
    </ReferenceInput> */}
  </Filter>
);
export const RealizeOrderList = (props) => {
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <List {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} className="grid-list" classes={{ actions: "my-action", main: "main", content: "content", }}
      title="Realize Order List" filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
        {propsObj.hasEdit && <EditButton />}
        <TextField source="ccnno" label="CCN No" />
        <TextField source="pName" label="Name" />
        <TextField source="pMobNo" label="Mobile Number" />
        <TextField source="pEmail" label="Email address" />
        <DisplayDate source="date" label="Entry Date"  locales="fr-FR" />
        <TextField source="hBillNo" label="Hospital Bill no." />
        <NumberField source="hBillAmt" label="Hospital bill Amount" />
        <DisplayDate source="doa" label="DOA"  locales="fr-FR" />
        <DisplayDate source="dod" label="DOD"  locales="fr-FR" />
        <DisplayDate source="frecDate" label="Received At HDC"  locales="fr-FR" />
        <DisplayDate source="fSubDate" label="File Submission Date"  locales="fr-FR" />
        <ReferenceField label="Hospital" source="hospitalId" reference="hospitals" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="TPA" source="tpaId" reference="tpas" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Corporate" source="corporateId" reference="corporates" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <TextField source="cardNo" label="Card No" />
        <ReferenceField source="insurerId" reference="insurers" link={false}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifyDate" label="Updated Date" locales="fr-FR" />
        {/*         
        <TextField source="cityCode" label="City Code" />
        <TextField source="cityName" label="City Name" />
        <ReferenceField source="stateId" reference="states" link={false} label="State" >
                        <TextField source="stateName" />
                    </ReferenceField>
      
      <ReferenceField source="insertedBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="insertedAt" label="Created Date"/>
            <ReferenceField source="updatedBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="updatedAt" label="Updated Date"/> */}
      </Datagrid>
    </List>
  );
};
