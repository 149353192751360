import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,SelectInput,
    Create, SimpleForm, TextInput, DateInput,ReferenceInput,
    BooleanInput, NumberInput, ImageInput, ImageField,FormDataConsumer
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { AddressFields } from './AddressFields';

export const ZipCodeCreate = props => (
    <Create {...props} title="City">
        <SimpleForm redirect="list" defaultValue={{ citytypeid: 1 }}
        variant="outlined">
                <FormDataConsumer>
            {formDataProps => (
              <AddressFields {...formDataProps} />
            )}
          </FormDataConsumer>
            <TextInput validate={required()} source="zipCode" label="ZipCode" fullWidth={true} /> 
            
            
            <BooleanInput initialValue={true} source="isActive" />
        </SimpleForm>
    </Create>
);