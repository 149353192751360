import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField, ReferenceField,Filter,TextInput,ReferenceInput,AutocompleteInput,DateInput,
    BooleanField, EditButton, DeleteButton, ImageField
} from 'react-admin';
import { DisplayDate ,DisplayDateTime } from '../../common/DisplayDate';

const Filters = props => (
    <Filter {...props} variant="outlined" className="filter-block">
      <DateInput variant="outlined" label="From Date" source="insertedDate~gte" alwaysOn />  
      <DateInput variant="outlined" label="To Date" source="insertedDate~lte" alwaysOn />  
      <TextInput variant="outlined" label="From Mail" source="fromMailId~like" alwaysOn />
      <TextInput variant="outlined" label="Mail Subject" source="mailSubject~like" alwaysOn/>
      <ReferenceInput perPage={25} sort={{ field: 'vid', order: 'ASC' }}  filterToQuery={searchText => ({ 'vid~like': searchText })}
            label="Claim Order" source="linkId" reference="claim-ords" fullWidth={true}  allowEmpty alwaysOn>
            <AutocompleteInput optionText="vid" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
      </ReferenceInput>
      
    </Filter>
  );

export const PolicyMailLogList = props => (
    <List {...props} title="Policy Mail Log"  filters={<Filters />} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <EditButton />
            <DisplayDateTime source="insertedDate" label="Date" />
            <TextField source="fromMailId" label="From Mail" />
            <TextField source="mailSubject" label="Mail Subject" />
            <ReferenceField source="statusId" reference="lookups" link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="remarks" label="Remarks" />
            <ReferenceField source="linkId" reference="claim-ords" link={false}>
                <TextField source="vid" />
            </ReferenceField>
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDate  source="modifydate" label="Updated Date" locales="fr-FR" />
            
        </Datagrid>
    </List>
);