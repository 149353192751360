import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../../common/formStyles";
import * as apiClient from "../../../common/apiClient";


export const AllotmentFields = ({ calimOrdId, formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});
    //console.log(calimOrdId, "======res calimOrdId=======");
    const getClaimAmount = () => {
        apiClient.GetClaimAmount(calimOrdId).then(res => {
            //console.log(res, "======res GetClaimAmount=======");
            if (res.length > 0) {
                form.change("foClaimFeeAmount", res[0].claimAmt);
            }
        }).catch(err => {
            console.log(err, "======err GetClaimAmount=======");
        });
    }
    const calculateTotal = (foClaimFeeAmount, foIncentiveAmount, foOtherAmount, fotaAmount) => {
        form.change("foTotalAmount",parseFloat(foClaimFeeAmount) + parseFloat(foIncentiveAmount) + parseFloat(foOtherAmount) + parseFloat(fotaAmount));
    }

    return (
        <div className="alotment-field-parent">
            <BooleanInput variant="outlined" source="isFoPaymentRaised" label="FO Payment Raised"
                onChange={(e) => {
                    if (e) {
                        getClaimAmount();
                        form.change("foIncentiveAmount", 0);
                        form.change("foOtherAmount", 0);
                        form.change("fotaAmount", 0);
                        form.change("foTotalAmount", 0);
                        form.change("foTotalPaidAmount", 0);
                        form.change("foPaymentRaisedDate", new Date());
                    }
                }}
                fullWidth={true} className="fo-payment-raised" />
            <DateInput variant="outlined" label="Fo Payment Raised Date" source="foPaymentRaisedDate" fullWidth={true} formClassName={classes.first_inline_input} />
            <NumberInput disabled
            variant="outlined" source="foClaimFeeAmount" label="Claim Fee Amount" fullWidth={true} formClassName={classes.last_inline_input} />
            <NumberInput
            onChange={(e) => {calculateTotal(formData.foClaimFeeAmount, e.target.value,formData.foOtherAmount, formData.fotaAmount );}}
             variant="outlined" source="foIncentiveAmount" fullWidth={true} label="Incentive Amount" formClassName={classes.first_inline_input} />
            <NumberInput 
            onChange={(e) => {calculateTotal(formData.foClaimFeeAmount,formData.foIncentiveAmount, e.target.value, formData.fotaAmount );}}
            variant="outlined" source="foOtherAmount" fullWidth={true} label="Other Amount" formClassName={classes.last_inline_input} />
            <NumberInput 
            onChange={(e) => {calculateTotal(formData.foClaimFeeAmount,formData.foIncentiveAmount,formData.foOtherAmount,  e.target.value );}}
            variant="outlined" source="fotaAmount" fullWidth={true} label="TA Amount" formClassName={classes.first_inline_input} />
            <NumberInput disabled variant="outlined" source="foTotalAmount" fullWidth={true} label="Total Amount" formClassName={classes.last_inline_input} />
            <NumberInput variant="outlined" source="foTotalPaidAmount" fullWidth={true} label="Total Paid Amount" formClassName={classes.first_inline_input} />
            <TextInput variant="outlined" source="paymentMode" fullWidth={true} label="Payment Mode" formClassName={classes.last_inline_input} disabled={!formData.paymentPaid}
            
            />
            <BooleanInput variant="outlined" source="paymentPaid"
            onChange={(e) => {
                if (e) {
                    form.change("foPaymentDate", new Date());
                }
                else
                {
                    form.change("foPaymentDate", null);
                }
            }}
             fullWidth={true} label="Payment Paid" formClassName={classes.first_inline_input} />
            <DateInput variant="outlined" source="foPaymentDate" fullWidth={true} label="Payment Paid Date" formClassName={classes.last_inline_input} disabled={!formData.paymentPaid}/>
            <TextInput variant="outlined" label="Fo Payment Neft Det" source="foPaymentNeftDet" fullWidth={true} formClassName={classes.last_three_input} disabled={!formData.paymentPaid} />
            <TextInput variant="outlined" source="paymentRemarks" label="Payment Remarks" className="fo-payment-remarks" disabled={!formData.paymentPaid}/>
            
        </div>
    )
}
