import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import  PopupCreateToolbar  from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

const RealizationDocumentEdit = props => {
    const classes = useStyles({});
    console.log(props);
    const handleSubmit = async (formdata) => {
        console.log(formdata);
        let documentUrl = await apiClient.UploadFile("docUrl", formdata.docUrl);
        let data = props.record;
        data.docUrl = documentUrl;
        data.docType = formdata.docType;
        data.docValue = parseInt(formdata.docValue);
        data.modifyBy = 1;
        data.modifyDate = data.createdDate;
        apiClient.updateRealizationDocument(data).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="realiz-ord-dets" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined">

                <TextInput source="docType" label="Document name" fullWidth={true} />
                <TextInput source="docValue" label="Document value" fullWidth={true} />

                <FileInput fullWidth={true} source="docUrl" label="Document">
                    <FileField source="docUrl" title="Document" />
                </FileInput>
            </SimpleForm>
        </Edit>
    );
}
export default RealizationDocumentEdit;