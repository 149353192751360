import React, { useRef, useState, useCallback } from 'react';
import {
    Edit, Create, SimpleForm, TextInput, ReferenceInput, Toolbar, SaveButton, Button,
    SelectInput, DateInput, required, AutocompleteInput
} from 'react-admin';
import VideoUpload from "../upload/FileUploader";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
import CancelIcon from '@material-ui/icons/Cancel';
import * as apiClient from "../../common/apiClient";
import { toast } from 'react-toastify';
import moment from "moment";
import { AgGridReact } from 'ag-grid-react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

 const isShortValues = ["Yes", "No"];
  function isShortFormatter(params) {
    console.log(params, "======isShortFormatter========")
    return (params.value == 1) ? "Yes" : "No";
  }
export const columnsDef = [
    { "headerName": "FO Name", "field": "FONAME", resizable: true, width:120,},
    { "headerName": "VID", "field": "VID", resizable: true, width:120,},
    { "headerName": "Patient Name", "field": "PName", resizable: true, width:120,},
    
    { "headerName": "Policy Number", "field": "POLICYNO", resizable: true, width:150,},
    { "headerName": "Ins Claim Number", "field": "ICLAIMNO", resizable: true, width:150,},
    { "headerName": "Claim Type", "field": "CLAIMTYPE", resizable: true, },
    { "headerName": "Claim Fee Amount", "field": "FOClaimFeeAmount", resizable: true, width:120, },
    { "headerName": "Incentive Amount", "field": "FOIncentiveAMount", editable: true, singleClickEdit: true, resizable: true, },
    { "headerName": "TA Amount", "field": "FOTAAMount", resizable: true, editable: true, singleClickEdit: true },
    { "headerName": "Other Amount", "field": "FOOtherAMount", resizable: true, editable: true, singleClickEdit: true },
    { "headerName": "Total Amount", "field": "FOTotalAMount", resizable: true, },
    { "headerName": "Total Paid Amount", "field": "FOTotalPaidAmount", editable: true, singleClickEdit: true, resizable: true, },
    { "headerName": "NEFT Details", "field": "NeftDetails", resizable: true, editable: true, singleClickEdit: true },
    { "headerName": "Payment Mode", "field": "PaymentMode", resizable: true, editable: true, singleClickEdit: true },
    { "headerName": "Is Payment Paid", "field": "PaymentPaid", resizable: true, 
    cellEditor: 'agSelectCellEditor',
    valueFormatter: isShortFormatter,
      cellEditorParams: {
        values: isShortValues,
      },
    editable: true, singleClickEdit: true },
    { "headerName": "Payment Paid Date", "field": "PaymentPaidDt", resizable: true, editable: true, singleClickEdit: true },
    { "headerName": "Payment Remarks", "field": "PaymentRemarks", resizable: true, editable: true, singleClickEdit: true },
];

const PostCreateToolbar = props => (
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true} onSave={props.onSave} label="Fetch Payments" />
        <Button
            variant="contained" color="primary"
            label="Back" size="medium"
            startIcon={<CancelIcon />}
            style={{ marginLeft: "30px" }}
            onClick={props.onCancel}
        />
    </Toolbar>
);

export const ActivityCreate = props => {
    const classes = useStyles({});
    const gridRef = useRef<any>();
    const foPaymentId = props.foPaymentId
    const [gridColumns, setGridColumns] = useState<any[]>(columnsDef);
    const [result, setResult] = useState<any[]>([]);
    const [filterdata, setFilterData] = useState<any>(null);

    let history = useHistory();

    const handleSubmit = async (formdata) => {
        console.log(formdata);
        let m = moment(formdata.fromDate);
        let fromDate = m.format("YYYY-MM-DD");
        m = moment(formdata.toDate);
        let toDate = m.format("YYYY-MM-DD");
        setFilterData(formdata);
        apiClient.getFOPaymentsForDates(fromDate, toDate).then(res => {
            if (res.result == "success")
                setResult(res.data);
        }).catch(err => {
            toast.error(err);
        })
    }

    const onFirstDataRendered = useCallback((params) => {
       // gridRef.current.api.sizeColumnsToFit();
    }, []);

    const onCellValueChanged = (event) => {
        // console.log(event);
        let rowNode = event.node;
        let data = event.data;
        let FOClaimFeeAmount = parseInt(data.FOClaimFeeAmount);
        let FOOtherAMount = parseInt(data.FOOtherAMount);
        let FOIncentiveAMount = parseInt(data.FOIncentiveAMount);
        let FOTAAMount = parseInt(data.FOTAAMount);
        let FOTotalAMount = FOClaimFeeAmount + FOOtherAMount + FOIncentiveAMount + FOTAAMount;
        rowNode.setDataValue('FOTotalAMount', FOTotalAMount);
    }

    const createPayments = () => {
        let fromDate = filterdata.fromDate;
        let toDate = filterdata.toDate;
        let data = {
            foPaymentDate: new Date().toISOString(),
            fromDate: new Date(fromDate).toISOString(),
            toDate: new Date(toDate).toISOString()
        }
        let userId= localStorage.getItem("userId");
        apiClient.addEntiyRecord("fo-payments", data).then(res => {
            console.log(res);
            if(res.id) {
                let poId = res.id;
                gridRef.current.api.forEachNode(async (rowNode, index) => {
                    let foData = {
                        employeeId : rowNode.data.EmployeeID,
                        foPaymentId : poId,
                        claimOrdId: parseInt(rowNode.data.ClaimOrdID),
                        claimOrdAllotId : rowNode.data.ClaimOrdAllotID,
                        fromDate: new Date(fromDate).toISOString(),
                        toDate: new Date(toDate).toISOString(),
                        foClaimFeeAmount : rowNode.data.FOClaimFeeAmount,
                        fotaAmount: parseInt(rowNode.data.FOTAAMount),
                        taDocUrl: "",
                        foIncentiveAmount : parseInt(rowNode.data.FOIncentiveAMount),
                        incDocUrl: "",
                        foOtherAmount :  parseInt(rowNode.data.FOOtherAMount),
                        foTotalAmount :  parseInt(rowNode.data.FOTotalAMount),
                        foTotalPaidAmount : parseInt(rowNode.data.FOTotalPaidAmount),
                        paymentPaid : (rowNode.data.PaymentPaid == "Yes" || rowNode.data.PaymentPaid == 1) ? true : false,
                        paymentPaidDt : new Date().toISOString(),
                        paymentMode : rowNode.data.PaymentMode,
                        neftDetails: rowNode.data.NeftDetails,
                        paymentRemarks : rowNode.data.PaymentRemarks,
                        createdBy : parseInt(userId!!),
                        createdDate : new Date().toISOString(),
                    };
                    console.log(foData, "===foData====");
                    await apiClient.addEntiyRecord("fo-payment-dets", foData);
                    history.push("fo-payments");
                });

            }else{
                toast.error("Error creating the payment info");    
            }
            
        }).catch(err => {
            toast.error(err);
        });
        //console.log(gridRef.current.api);
    }

    return (
        <>
            <Create title="Add FO Payment" {...props}>
                <SimpleForm className="payment-create-form" redirect="list" toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}  {...props} />}
                    variant="outlined">
                    <DateInput source="fromDate" fullWidth={true} formClassName={classes.first_inline_input} validate={[required()]} />
                    <DateInput source="toDate" fullWidth={true} formClassName={classes.last_inline_input} validate={[required()]} />
                </SimpleForm>
            </Create>
            {<div
                className="ag-theme-balham box-shadow"
                style={{
                    padding: '0px 24px',
                    height: '100vh',
                    width: '100%',
                    marginBottom: '50px'
                }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowSelection={'single'}
                    enableCellTextSelection={true}
                    onFirstDataRendered={onFirstDataRendered}
                    onCellValueChanged={onCellValueChanged}
                    columnDefs={gridColumns}
                    rowData={result}>
                </AgGridReact>
                {result && result.length > 0 && <Button
                    variant="contained" color="primary"
                    label="Save Payments" size="medium"
                    startIcon={<CancelIcon />}
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                        createPayments();
                    }}
                />}
            </div>}
        </>
    );
}