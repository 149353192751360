import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const HospitalRealDetCreate = props => {
    const classes = useStyles({});
    
    const handleSubmit = async (formdata) => {
         console.log(formdata);
        
        let data = {
            hospitalId: parseInt(props.hospitalId),
            tatindays: formdata.tatindays,
            billingPercentage: formdata.billingPercentage,
            remarks: formdata.remarks,
            createdBy:0,
            
        }
        console.log(data);
        apiClient.createHospitalRealisation(data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined">

                
                <NumberInput  validate={required()} fullWidth={true} source="tatindays" label="Tat in Days" />
                <NumberInput  validate={required()} fullWidth={true} source="billingPercentage" label="Billing Percentage" />
                <TextInput  validate={required()} fullWidth={true} source="remarks" label="Remarks" />
                 

                 

            </SimpleForm>
        </Edit>
    );
}
export default HospitalRealDetCreate;