import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,ReferenceInput, SelectInput,
    DateInput, BooleanInput, NumberInput,FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../CreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../formStyles";

 
const PostCreateToolbar = props => (
    
    <Toolbar {...props}>
        <SaveButton submitOnEnter={true}  onSave={props.onSave} />
        <Button
        variant="contained" color="primary"
        label="Back" size="medium"
        startIcon={<CancelIcon />}
        style={{marginLeft:"30px"}}
        onClick={props.onCancel}
        />
    </Toolbar>
);
const ConfigCreate = props => {
    const classes = useStyles({});
    
    const handleSubmit = async (formdata) => {
         console.log(formdata);
        
        let data = { 
            insurerId: parseInt(props.id),
                claimTypeId: formdata.claimTypeId,
                tatindays:  formdata.tatindays,
                billingPercentage: formdata.billingPercentage,
                billingAmount:formdata.billingAmount,
                remarks:  formdata.remarks,
                other:  formdata.other,
                professionalFee:formdata.professionalFee,
                incentiveType:  formdata.incentiveType,
                incentiveAmt:  formdata.incentiveAmt,
                otherChargesType:  formdata.otherChargesType,
                otherCharges:  formdata.otherCharges,
                
                createdBy: 0,
                createdDate: "2022-03-10T06:52:27.193Z",
                modifyBy: 0,
                modifydate: "2022-03-10T06:52:27.193Z"
             
            
        }
        console.log(data);
        apiClient.addEntiyRecord("insurer-inv-dets",data).then(res => {
            console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
        
    }
    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit} 
                toolbar={<PostCreateToolbar onSave={handleSubmit} onCancel={props.onCancel}   />} variant="outlined"> 
                <ReferenceInput  perPage={2000} source="claimTypeId" label="Claim Type" reference="lookups"  fullWidth={true} formClassName={classes.one_three_input}  sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'TCLM' }} >
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput> 
                 <NumberInput  validate={required()}  fullWidth={true} formClassName={classes.two_three_input}  source="tatindays" label="Tat In Days" /> 
                 <NumberInput  validate={required()}  fullWidth={true} formClassName={classes.last_three_input}  source="billingPercentage" label="Billing Percentage"  />  
                 <NumberInput  validate={required()} fullWidth={true} formClassName={classes.one_three_input} source="billingAmount" label="Billing Amount"   />   
                 <NumberInput  validate={required()} fullWidth={true} formClassName={classes.two_three_input} source="professionalFee" label="Professional Fee"   />   
                <TextInput  fullWidth={true}  formClassName={classes.last_three_input} source="remarks" label="Remarks" initialValue="" />  
                <TextInput  fullWidth={true} formClassName={classes.one_three_input} source="other" label="Other" initialValue="" />  
                <ReferenceInput  perPage={2000} source="incentiveType" label="Incentive Type" reference="lookups" fullWidth={true}  formClassName={classes.two_three_input} sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'IType' }} >
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput> 
                <NumberInput  validate={required()} fullWidth={true} formClassName={classes.last_three_input} source="incentiveAmt" label="Incentive Amt"   />   
                <ReferenceInput  perPage={2000} source="otherChargesType" label="Other Charges Type" reference="lookups" fullWidth={true} formClassName={classes.one_three_input} sort={{ field: 'name', order: 'ASC' }} filter={{ type: 'OCT' }} >
                    <SelectInput optionText="name" validate={required()} />
                </ReferenceInput> 
                <NumberInput  validate={required()} fullWidth={true} formClassName={classes.two_three_input} source="otherCharges" label="Other Charges"   />   

            </SimpleForm>
        </Edit>
    );
}
export default ConfigCreate;