import React, { useState, useCallback } from "react";
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField, TopToolbar, CreateButton, ExportButton,
  Filter, SelectInput, AutocompleteInput,
  ReferenceInput,
  SearchInput, TextInput
} from "react-admin";
import { DisplayDate } from "../../common/DisplayDate";
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';
import Print from "./InvoicePrint/InvoicePrint";
import Print2 from "./InvoicePrint2/InvoicePrint2";
import Print3 from "./Print3/Print3";
import Print4 from "./Print4/Print4";
import UploadPaymentDetails from "./UploadPaymentDetails";


const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    marginRight: 5,

    width: 650,
  },
}));

const Filters = props => (
  <Filter {...props} variant="outlined" className="filter-block">
    <TextInput variant="outlined" label="VID No" source="vid~like" alwaysOn />

    <TextInput variant="outlined" label="Policy No" source="policyNo~like" />
    <TextInput variant="outlined" label="Insurer Claim No" source="iclaimno~like" alwaysOn />
    <TextInput variant="outlined" label="Mobile Number" source="pMobNo~like" />

    <TextInput variant="outlined" label="TPA Claim no" source="claimno~like" alwaysOn />
    <TextInput variant="outlined" label="Patient Name" source="pName~like" alwaysOn/>
{/* 
    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput> */}

    {/* <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
      <AutocompleteInput optionText="name"  shouldRenderSuggestions={(val) => { return val && val.trim().length   >= 1 }} />
    </ReferenceInput> */}
  </Filter>
);

const ListActions = (props) => (
  <TopToolbar>
    {props.hasCreate && <CreateButton {...props} label="Create" />}
    {props.hasExport && <ExportButton {...props} />}
    {props.hasEdit && <Button onClick={props.onUploadPayment} {...props} >Upload Payment</Button>}
  </TopToolbar>
);

export const ClaimOrderList = (props) => {
  const myclasses = myStyles({});
  const [isPrint, setIsPrint] = useState(false);
  const [isPrint2, setIsPrint2] = useState(false);
  const [isPrint3, setIsPrint3] = useState(false);
  const [isPrint4, setIsPrint4] = useState(false);
  const [isPrintDialog, setIsDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(0);

  const [isDisplayPayments, setIsDisplayPayments] = useState(false);

  const onUploadPayment = () => {
    setIsDisplayPayments(true)
  }

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  const closeEdit = () => {
    setIsPrint(false)
    setIsPrint2(false)
    setIsPrint3(false)
    setIsPrint4(false)
    setIsDialog(false)
    setIsPrint(false);
    setIsPrint2(false);
    setIsDisplayPayments(false);
  }


  const PrintButton = (props) => (

    <Button onClick={props.click}  > {props.label}  </Button>
  )
  const PrintButton2 = (props) => (

    <Button onClick={props.click}  > Print Without Header </Button>
  )


  const onCancel = () => {
    setIsPrint(false);
    setIsPrint2(false);
    setIsPrint3(false);
    setIsPrint4(false);
    setIsDialog(false)
    setIsPrint(false)
    setIsPrint2(false)
    setIsDisplayPayments(false);
  }
  function showPopup(id, type) {

    setSelectedId(id)
    // if (type) {
    //   if (type == 'Prayer_Letter') {
    //     setIsPrint3(true);
    //   }
    //   if (type == 'Authorization_Letter') {
    //     setIsPrint4(true);
    //   }
    // }
    // else {
    //   setIsPrint(true);
    // }

    setIsDialog(true);

  }
  function showPopup2(id) {

    setSelectedId(id)
    //setIsPrint2(true);
    setIsDialog(true);
  }
  const DisplayFileLink = (props) => {
    let { record } = props;

    return (
      <>
        <PrintButton label={"Print"} click={(e) => showPopup(record.id, undefined)} />
        {/* <PrintButton2 click={(e) => showPopup2(record.id)} />
        <PrintButton label={"Prayer Letter"} click={(e) => showPopup(record.id, 'Prayer_Letter')} />
        <PrintButton label={"Authorization Letter"} click={(e) => showPopup(record.id, 'Authorization_Letter')} /> */}
      </>

    )
  }
  const DisplayFileLink2 = (props) => {
    let { record } = props;

    return (
      <PrintButton2 click={(e) => showPopup2(record.id)} />

    )
  }
  const DisplayFileLink3 = (props) => {
    let { record } = props;

    return (

      <PrintButton label={"Prayer Letter"} click={(e) => showPopup(record.id, 'Prayer_Letter')} />

    )
  }
  const DisplayFileLink4 = (props) => {
    let { record } = props;

    return (

      <PrintButton label={"Authorization Letter"} click={(e) => showPopup(record.id, 'Authorization_Letter')} />

    )
  }

  const handleClose = useCallback(() => {
    //setIsAddTpa(false);
  }, []);

  return (
    <React.Fragment>

      <List {...propsObj} exporter={false} bulkActionButtons={false} className="grid-list"
        actions={<ListActions {...props} onUploadPayment={onUploadPayment} />}
        classes={{ actions: "my-action", main: "main", content: "content", }}
        title="Claim Order List" filters={<Filters />} sort={{ field: 'id', order: 'DESC' }} >
        <Datagrid rowClick={false}>
          {propsObj.hasEdit && <EditButton />}
          <DisplayFileLink label="" source="id" />
          
          <TextField source="vid" label="VID" />
          <TextField source="claimno" label="TPA Claim No" />
          <TextField source="iclaimno" label="Insurer Claim No" />
          <DisplayDate source="initiateOn" label="Initiate On" locales="fr-FR" />
          <TextField source="initiateAt" label="Initiate At" />
          <ReferenceField source="insurerId" reference="insurers" link={false}>
            <TextField source="name" />
          </ReferenceField>
          
          <TextField source="pName" label="Patient Name" />
           
          <ReferenceField label="Hospital" source="hospId" reference="hospitals" link={false}>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField link={false} label="Status" source="statusId" reference="lookups" fullWidth={true} >
            <TextField source="name" />
          </ReferenceField>  
        </Datagrid>
      </List>
      <Drawer
        anchor="right"
        onClose={handleClose}
        classes={{ paper: myclasses.drawerPaper, }}
        open={isPrint || isPrint2 || isPrint3 || isPrint4 ||isDisplayPayments}>
        
        {isDisplayPayments && <UploadPaymentDetails
          onClose={onCancel}
          onCancel={onCancel}
          // foPaymentId={foPaymentId}
          {...props} />}
        {isPrint && <Print
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={selectedId}
          isOpen={isPrint}
          {...props} />}
        {isPrint2 && <Print2
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={selectedId}
          isOpen={isPrint2}
          {...props} />}
        {isPrint3 && <Print3
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={selectedId}
          isOpen={isPrint3}
          {...props} />}

        {isPrint4 && <Print4
          onClose={closeEdit}
          onCancel={onCancel}
          invoiceId={selectedId}
          isOpen={isPrint4}
          {...props} />}

      </Drawer>
      
      <Dialog
                open={isPrintDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Print Reports"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div style={{display:'flex',flexDirection:'column'}} >
                    <Button variant="contained"  color="primary" style={{marginTop:10}} onClick={()=>{setIsPrint(true);setIsDialog(false); }}  >
                        Print with Header
                    </Button>
                    <Button variant="contained"  color="primary" style={{marginTop:10}} onClick={()=>{setIsPrint2(true);setIsDialog(false); }}  >
                        Print Without Header 
                    </Button>
                    <Button variant="contained"  color="primary" style={{marginTop:10}} onClick={()=>{setIsPrint3(true);setIsDialog(false); }}  >
                        Print Prayer Letter 
                    </Button>
                    <Button variant="contained"  color="primary" style={{marginTop:10}} onClick={()=>{setIsPrint4(true);setIsDialog(false); }}  >
                        Print Authorization Letter 
                    </Button>
                    <Button variant="contained"  color="secondary" style={{marginTop:10}} onClick={onCancel}  >
                        Cancel
                    </Button>
                    </div>
                </DialogActions>
            </Dialog>
    </React.Fragment>
  );
};
