import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput,
     BooleanInput, NumberInput,ReferenceInput,SelectInput } from 'react-admin';

export const StateEdit = props => (
    <Edit {...props} title="State">
        <SimpleForm>
            
            <ReferenceInput  variant="outlined"  source="countryId" reference="countries">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput   variant="outlined"  source="name" />
            <TextInput  variant="outlined"  source="code" />

            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);