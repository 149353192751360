import React, { useState, useCallback } from 'react';

import {
  required, minLength, maxLength, minValue, maxValue,
  number, regex, email, choices,
  TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
  Edit, SimpleForm, TextInput, RichTextField,
  ReferenceInput, SelectInput, EditButton, NumberField,
  DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
  ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";
import { toast } from 'react-toastify';
import * as apiClient from "../../common/apiClient";
import moment from 'moment';

export const BillingInfoFields = ({ formData, ...rest }) => {
  const form = useForm();
  const classes = containerStyles({});
  const getBillingDetails = (v) => {
    if (!v) {
      //form.change("incentiveType", 0);
      //form.change("otherChargesType", 0);
      //form.change("incentiveAmt", 0);
      form.change("professionalFee", 0);
      //form.change("otherCharges", 0);
      //form.change("invoiceRaisedDate", "")
      return;
    }
    if (!formData.invoiceToTypeId) {
      form.change("isInvoiceRaised", false);
      toast.error("Please select Invoice Type");
      return;
    }
    //console.log(formData, "==========formDataProps========");
    let ClaimOrdId: any = formData.id;
    let InvoiceToTypeId: any = formData.invoiceToTypeId;
    let ToId: any = -1;
    apiClient.GetBillingAmountDetails(ClaimOrdId, InvoiceToTypeId, ToId).then(res => {
      //console.log(res, "=======getBillingDetails======");
      form.change("invoiceRaisedDate", new Date())
      if (res.length > 0) {
        let d = res[0];
        // form.change("incentiveType", d.IncentiveType);
        // form.change("otherChargesType", d.OtherChargesType);
        // form.change("incentiveAmt", d.IncentiveAmt);
        form.change("professionalFee", d.ProfessionalFee);
        //form.change("otherCharges", d.OtherCharges);
      }

    }).catch(err => {
      toast.error(err);
    });
  }
  return (
    <div className="billing-info-fields">

      <ReferenceInput variant="outlined"

        perPage={500} sort={{ field: 'name', order: 'ASC' }}
        label="Status" source="statusId" reference="lookups" formClassName={classes.one_three_input}
        filter={{ type: 'RO' }} fullWidth={true} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput variant="outlined" label="Final Closure Date " source="finalClosureDate" fullWidth={true} formClassName={classes.two_three_input} disabled />

      <ReferenceInput variant="outlined"

        perPage={500} sort={{ field: 'name', order: 'ASC' }}
        label="Invoice Type" source="invoiceToTypeId" reference="lookups" formClassName={classes.last_three_input}
        filter={{ type: 'TOTP' }} fullWidth={true} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput onChange={(v) => { getBillingDetails(v); }} label="Is Invoice Raised" source="isInvoiceRaised" fullWidth={true} formClassName={classes.one_three_input} />
      <DateInput defaultValue={moment(new Date()).format('YYYY-MM-DD')} disabled
        variant="outlined" label="Invoice Raise Date " source="invoiceRaisedDate" fullWidth={true} formClassName={classes.two_three_input} />
      <div></div>
      <NumberInput variant="outlined" label="Professional Fee "  disabled source="professionalFee" fullWidth={true} formClassName={classes.one_three_input} />
      <ReferenceInput  variant="outlined"

        perPage={500} sort={{ field: 'name', order: 'ASC' }}
        label="Incentive Type" source="incentiveType" reference="lookups" formClassName={classes.two_three_input}
        filter={{ type: 'IType' }} fullWidth={true} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput variant="outlined" label="Incentive Amount "  source="incentiveAmt" fullWidth={true} formClassName={classes.last_three_input} />

      <ReferenceInput  variant="outlined"
        
        perPage={500} sort={{ field: 'name', order: 'ASC' }}
        label="Other Charges Type" source="otherChargesType" reference="lookups" formClassName={classes.one_three_input}
        filter={{ type: 'OCT' }} fullWidth={true} >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <NumberInput variant="outlined" label="Other Charges "   source="otherCharges" fullWidth={true} formClassName={classes.two_three_input} />
      <NumberInput variant="outlined" label="Extra Coneyance Amount "  source="extraConeyanceAmt" fullWidth={true} formClassName={classes.last_three_input} />

      <TextInput variant="outlined" disabled label="Invoice No. " source="invoiceNo" fullWidth={true} formClassName={classes.one_three_input} />
      <DateInput variant="outlined" disabled label="Invoice Date " source="invoiceDate" fullWidth={true} formClassName={classes.two_three_input} />
      
      <DateInput variant="outlined" disabled label="Invoice Submitted Date " source="invoiceSubDate" fullWidth={true} formClassName={classes.two_three_input} />
      <DateInput variant="outlined" disabled label="Paymt. Rcvd. Date " source="paymentRcvdDt" fullWidth={true} formClassName={classes.last_three_input} />
      <TextInput variant="outlined" disabled multiline label="Neft Details" source="neftDetails" fullWidth={true} formClassName={classes.one_three_input} />
      <NumberInput variant="outlined" label="Deduction Amt "  source="deductionAmt" fullWidth={true} formClassName={classes.two_three_input} />
      <TextInput variant="outlined"   multiline label="Deduction Reason" source="deductionReason" fullWidth={true} formClassName={classes.one_three_input} />

                

    </div>
  )
}
