import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,ReferenceField,
    BooleanField, EditButton, DeleteButton, ImageField
} from 'react-admin';

export const PolicyMailConfigList = props => (
    <List {...props} title="Policy Mail Config">
        <Datagrid rowClick="show">
            <EditButton />
            <TextField source="fromMailId" label="From Mail ID" />
            <ReferenceField source="insurerId" reference="insurers" label="Insurer"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <TextField source="mailSubject" label="Mail Subject" />
            <TextField source="mailBody" label="Mail Body" />
            
        </Datagrid>
    </List>
);