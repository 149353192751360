import React, { useCallback } from 'react';
import {
    useRefresh,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import * as apiClient from "./apiClient";

const CDeleteButton = props => {
    const refresh = useRefresh();
    const deleteRecord = () => {
        var userResponse = window.confirm("Are you sure, you want to delete the record?\nPress Ok to delete");
        if (!userResponse)
            return;
        apiClient.deleteRecord(props.resource, props.record.id).then(res => {
            refresh();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Button
            color="secondary" disableElevation
            size="medium"
            redirect={ false }
            onClick={ deleteRecord }
            startIcon={ <DeleteIcon /> }
        />
    )
};
export default CDeleteButton;