import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}


const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
});

export default function CasesList({data,onExport}) {
    const classes = useStyles();
    return (
        <Table className={classes.table} aria-label="customized table">
            <TableHead>
                <TableRow>
                    
                    <StyledTableCell><span><b></b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>CL</b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>RI</b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>PA_CI</b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>DEATH VERIF.</b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>HOSPITAL_VERIF.</b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>OTHER</b></span></StyledTableCell>
                    <StyledTableCell align="right"><span><b>NO_INFORMATION_DOCUMENT</b></span></StyledTableCell>
                    
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((row,index) => (
                    <StyledTableRow key={index} style={ row.Descriptions==='Total :'?{backgroundColor:'darkcyan',color:'white',border:'1px'}:null} >
                        
                        <StyledTableCell component="th" scope="row">
                        <img src={row.IconUrl} height={30}  />
                            <span style={{marginLeft:'10px'}} ><b>{row.Descriptions} </b></span>
                        </StyledTableCell>
                        <StyledTableCell align="right">
                        {row.CL>0 &&<a href='#' onClick={()=>onExport(row.Ord,"CL")}>{row.CL}</a>}
                            
                            </StyledTableCell>
                        <StyledTableCell align="right">
                            {row.RI>0 &&<a href='#' style={{cursor:'pointer'}} onClick={()=>onExport(row.Ord,"RI")}>{row.RI}</a>}
                            
                            </StyledTableCell>
                        <StyledTableCell align="right">{row.PA_CI>0 && <a href='#' onClick={()=>onExport(row.Ord,"PA_CI")}>{row.PA_CI}</a>}</StyledTableCell>
                        <StyledTableCell align="right">{row.DEATH_VERIF>0 &&<a href='#' onClick={()=>onExport(row.Ord,"DEATH_VERIF")}>{row.DEATH_VERIF}</a>}</StyledTableCell>
                        <StyledTableCell align="right">{row.HOSPITAL_VERIF>0 &&<a href='#' onClick={()=>onExport(row.Ord,"HOSPITAL_VERIF")}>{row.HOSPITAL_VERIF}</a>}</StyledTableCell>
                        <StyledTableCell align="right">{row.OTHER>0 &&<a href='#' onClick={()=>onExport(row.Ord,"OTHER")}>{row.OTHER}</a>}</StyledTableCell>
                        <StyledTableCell align="right">{row.NO_INFORMATION_DOCUMENT>0 &&<a href='#' onClick={()=>onExport(row.Ord,"NO_INFORMATION_DOCUMENT")}>{row.NO_INFORMATION_DOCUMENT}</a>}</StyledTableCell>
                        
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
}

// {
//     "Ord": "02",
//     "Descriptions": "3-5 days",
//     "CL": 0,
//     "RI": 10,
//     "PA_CI": 0,
//     "DEATH VERIF.": 0,
//     "HOSPITAL VERIF.": 0,
//     "OTHER": 0,
//     "NO INFORMATION / DOCUMENT": 0,
//     "TotalCount": 0,
//     "IconUrl": "https://hdfiles.blob.core.windows.net/veritas/Retails.png"
// },
