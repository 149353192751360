import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,FormDataConsumer,
    email,
    choices, Button, TextField, BooleanField,
    Edit, SimpleForm, TextInput, Create, AutocompleteInput, TabbedForm, FormTab,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import { DisplayDate } from '../../../common/DisplayDate';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";
import moment from 'moment';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { AllotmentFields } from "./Allotmentfields";

const DisplayFileLink = (props) => {
    let { record, source } = props;
    console.log(props, "========propspropspropsprops=====");
    console.log(source, "========source=====");

    if (record && source && record[source] && record[source].length > 0)
        return (
            <a href={record[source]}>Download File</a>
        )
    else {
        return null
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const AllotmentEdit = props => {
    const classes = useStyles({});
    const [value, setValue] = React.useState(0);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    console.log(props);
    const handleSubmit = async (formdata) => {
        console.log(formdata, "****formdata*****");
        if (formdata.otherDetails == undefined || formdata.otherDetails == null) {
            formdata.otherDetails = "";
        }



        let userId = localStorage.getItem("userId");
        let m = moment(new Date());
        let data = props.record;

        if (formdata.taDocUrl) {
            let taDocUrl = await apiClient.UploadFile("taDocUrl", formdata.taDocUrl);
            data.taDocUrl = taDocUrl;
        }
        if (formdata.incDocUrl) {
            let incDocUrl = await apiClient.UploadFile("incDocUrl", formdata.incDocUrl);
            data.incDocUrl = incDocUrl;
        }



        data.employeeId = formdata.employeeId;
        data.otherDetails = formdata.otherDetails;
        data.isInsured = formdata.isInsured;
        data.isHospitalised = formdata.isHospitalised;
        data.iscompleted = formdata.iscompleted;
        data.isReallotment1 = formdata.IsReallotment1 ? true : false;
        data.reallot1Date = formdata.Reallot1Date;
        data.reallot1Reason = formdata.Reallot1Reason;
        data.isReallotment2 = formdata.IsReallotment2 ? true : false;
        data.reallot2Date = formdata.Reallot2Date;
        data.reallot2Reason = formdata.Reallot2Reason;
        data.isVerified = formdata.isVerified;
        data.foVerifyRemarks = formdata.foVerifyRemarks;
        data.allotedOn = moment(formdata.allotedOn).toISOString()
        data.isDocCopyReceived = formdata.isDocCopyReceived;
        data.docCopyReceivedThrough = formdata.docCopyReceivedThrough;
        data.isFoPaymentRaised = formdata.isFoPaymentRaised ? true : false;
        data.foPaymentNeftDet = formdata.foPaymentNeftDet;

        data.foClaimFeeAmount = formdata.foClaimFeeAmount;
        data.foIncentiveAmount = formdata.foIncentiveAmount;
        data.foOtherAmount = formdata.foOtherAmount;
        data.fotaAmount = formdata.fotaAmount;
        data.foTotalAmount = formdata.foTotalAmount;
        data.foTotalPaidAmount = formdata.foTotalPaidAmount;
        data.neftDetails = formdata.neftDetails;
        data.paymentMode = formdata.paymentMode;
        data.paymentPaid = formdata.paymentPaid ? true : false; 
        data.paymentRemarks = formdata.paymentRemarks;

        if (formdata.foPaymentRaisedDate) {
            let pd = moment(formdata.foPaymentRaisedDate);
            data.foPaymentRaisedDate = pd.toISOString();
        }

        if (formdata.foPaymentDate) {
            data.foPaymentDate = moment(formdata.foPaymentDate).toISOString()
        }
        if (formdata.docCopyReceivedOn) {
            data.docCopyReceivedOn = moment(formdata.docCopyReceivedOn).toISOString()
        }
        if (!data.createdBy) data.createdBy = 1;
        if (!data.createdDate) data.createdDate = m.toISOString();
        data.modifyBy = parseInt(userId);
        data.modifyDate = m.toISOString();


        var keys = Object.keys(data);
        keys.forEach((item) => {
            if (data[item] == null) {
                delete data[item];
            }
        });

        apiClient.updateEntiyRecord("claim-ord-allots", data).then(res => {
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Edit {...props} id={props.record.id} resource="claim-ord-allots" redirect={false}>
            <SimpleForm
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                variant="outlined" syncWithLocation={false}>

                <AppBar position="static" className='tabs-parent' formClassName="tabs-parent">
                    <Tabs value={value} onChange={handleChange} variant="fullWidth" aria-label="simple tabs example">
                        <Tab label="Basic Details" {...a11yProps(0)} />
                        <Tab label="Payment Details" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} variant="outlined" index={0} className="tab-panel-fullwidth" formClassName="tab-panel-fullwidth">
                    <DateInput variant="outlined" label="Alloted On" source="allotedOn" fullWidth={true} formClassName={classes.first_inline_input} />
                    <ReferenceInput variant="outlined"
                        perPage={25} sort={{ field: 'name', order: 'ASC' }}
                        filterToQuery={searchText => ({ 'name~like': searchText })}
                        validate={[required()]} label="Employee Name" source="employeeId" reference="employees" fullWidth={true}
                        formClassName={classes.last_inline_input}
                    >
                        <AutocompleteInput className="autocomplete-combo" formClassName="autocomplete-combo" variant="outlined" optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                    </ReferenceInput>

                    <TextInput variant="outlined" source="otherDetails" label="Remarks" fullWidth={true} />
                    <BooleanInput variant="outlined" source="isVerified" label="Verified" fullWidth={true} />
                    <TextInput variant="outlined" source="foVerifyRemarks" label="FO Remarks" fullWidth={true} />

                    <BooleanInput variant="outlined" source="isInsured" label="Insured Part" fullWidth={true} formClassName={classes.one_three_input} />
                    <BooleanInput variant="outlined" source="isHospitalised" label="Hospital Part" fullWidth={true} formClassName={classes.two_three_input} />
                    <BooleanInput variant="outlined" source="iscompleted" label="Whole Part" fullWidth={true} formClassName={classes.last_three_input} />

                    <BooleanInput variant="outlined" label="Reallotment 1" source="IsReallotment1" fullWidth={true} formClassName={classes.one_three_input} />
                    <DateInput variant="outlined" label="Reallotment 1 Date" source="Reallot1Date" fullWidth={true} formClassName={classes.two_three_input} />
                    <TextInput variant="outlined" label="Reallotment 1 Reason" multiline source="Reallot1Reason" fullWidth={true} />

                    <BooleanInput variant="outlined" label="Reallotment 2" source="IsReallotment2" fullWidth={true} formClassName={classes.one_three_input} />
                    <DateInput variant="outlined" label="Reallotment 2 Date" source="Reallot2Date" fullWidth={true} formClassName={classes.two_three_input} />
                    <TextInput variant="outlined" label="Reallotment 2 Reason" multiline source="Reallot2Reason" fullWidth={true} />

                    <BooleanInput variant="outlined" source="isDocCopyReceived" label="Document Copy Received" fullWidth={true} formClassName={classes.one_three_input} />
                    <DateInput variant="outlined" label="Document Copy Received On" source="docCopyReceivedOn" fullWidth={true} formClassName={classes.two_three_input} />
                    <ReferenceInput variant="outlined" label="Document  Received Through" formClassName={classes.last_three_input}
                        perPage={5000} source="docCopyReceivedThrough"
                        fullWidth={true}
                        filter={{ type: 'DOCT' }}
                        reference="lookups" >
                        <SelectInput variant="outlined" optionText="name" formClassName={classes.last_three_input} />
                    </ReferenceInput>
                </TabPanel>
                <TabPanel value={value} index={1} className="tab-panel-fullwidth" formClassName="tab-panel-fullwidth">
                    
                    <FormDataConsumer>
                        {formDataProps => (
                            <AllotmentFields {...props} {...formDataProps} />
                        )}
                    </FormDataConsumer>

                    
                    <FileInput fullWidth={true} source="taDocUrl" label="FO Document">
                        <FileField source="taDocUrl" title="File" />
                    </FileInput>
                    <DisplayFileLink label="FO Document" source="taDocUrl" {...props} />

                    <FileInput fullWidth={true} source="incDocUrl" label="Bank Payment advice">
                        <FileField source="incDocUrl" title="File" />
                    </FileInput>
                    <DisplayFileLink label="Bank Payment advice" source="taDocUrl" {...props} />
                </TabPanel>



            </SimpleForm>
        </Edit>
    );
}
export default AllotmentEdit;