import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const FoPaymentfields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = containerStyles({});


    return (
        <div className="fopayment-field-parent">
            <NumberInput variant="outlined" fullWidth={ true }
                onChange={ (v) => {
                    let total = parseInt(v.target.value) + formData.foIncentiveAmount + formData.foOtherAmount + formData.fotaAmount;
                    form.change("foTotalAmount", total);
                } }
                source="foClaimFeeAmount" label="Claim Fee Amount" formClassName={ classes.first_inline_input } />

            <NumberInput variant="outlined" fullWidth={ true }
                onChange={ (v) => {
                    let total = parseInt(v.target.value) + formData.foClaimFeeAmount + formData.foOtherAmount + formData.fotaAmount;
                    form.change("foTotalAmount", total);
                } }
                source="foIncentiveAmount" label="Incentive Amount" formClassName={ classes.last_inline_input } />
            <NumberInput variant="outlined" fullWidth={ true }
                onChange={ (v) => {
                    let total = parseInt(v.target.value) + formData.foClaimFeeAmount + formData.foIncentiveAmount + formData.fotaAmount;
                    form.change("foTotalAmount", total);
                } }
                source="foOtherAmount" label="Other Amount" formClassName={ classes.first_inline_input } />
            <NumberInput variant="outlined" fullWidth={ true }
                onChange={ (v) => {
                    let total = parseInt(v.target.value) + formData.foClaimFeeAmount + formData.foIncentiveAmount + formData.foOtherAmount;
                    form.change("foTotalAmount", total);
                } }
                source="fotaAmount" label="TA Amount" formClassName={ classes.last_inline_input } />
            <NumberInput variant="outlined" disabled={true} fullWidth={ true } source="foTotalAmount" label="Total Amount" formClassName={ classes.first_inline_input } />
            <NumberInput variant="outlined" fullWidth={ true } source="foTotalPaidAmount" label="Total Paid Amount" formClassName={ classes.first_inline_input } />

            <TextInput variant="outlined" fullWidth={ true } formClassName={ classes.last_inline_input } source="neftDetails" label="NEFT Details" />
            <TextInput variant="outlined" fullWidth={ true } formClassName={ classes.first_inline_input } source="paymentMode" label="Payment Mode" />
            <BooleanInput variant="outlined" fullWidth={ true } formClassName={ classes.last_inline_input } source="paymentPaid" label="Payment Paid" />
            <DateInput variant="outlined" fullWidth={ true } formClassName={ classes.first_inline_input } source="paymentPaidDt" label="Payment Paid Date" />
            <TextInput variant="outlined" fullWidth={ true } source="paymentRemarks" label="Payment Remarks" />
        </div>
    )
}
