import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,ReferenceField,
    BooleanField, EditButton, DeleteButton, ImageField,Filter,TextInput
} from 'react-admin';


const Filters = props => (
    <Filter {...props} variant="outlined" className="filter-block">
      <TextInput variant="outlined" label="zipCode" source="zipCode~like" alwaysOn />
  
      
  {/* 
      <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
        label="Insurer Name" source="insurerId" reference="insurers" fullWidth={true} allowEmpty alwaysOn>
        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
      </ReferenceInput> */}
  
      {/* <ReferenceInput label="Hospital" source="hospitalId" reference="hospitals" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
        <AutocompleteInput optionText="name"  shouldRenderSuggestions={(val) => { return val && val.trim().length   >= 1 }} />
      </ReferenceInput> */}
    </Filter>
  );
export const ZipCodeList = props => (
    <List {...props} title="ZipCode" filters={<Filters />}>
        <Datagrid rowClick="show">
            <EditButton />
            <TextField source="zipCode" label="Zip Code" />            
            <ReferenceField source="stateId" reference="states" label="State"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="districtId" reference="districts" label="District"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="cityId" reference="cities" label="City"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            
            <BooleanField source="isActive" label="Is Active" />
        </Datagrid>
    </List>
);