import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,
    BooleanField, EditButton, ReferenceField,Filter,TextInput
}
    from 'react-admin';
    const Filters = props => (
        <Filter {...props} variant="outlined" className="filter-block">
          <TextInput variant="outlined" label="State" source="name~like" alwaysOn /> 
        </Filter>
      );
export const StateList = props => (
    <List {...props}  title="State" filters={<Filters />}>
        <Datagrid rowClick={false}>
        <EditButton />
            <TextField source="name" />
            <TextField source="code" />
            <ReferenceField source="countryId" reference="countries" label="Country"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" />
        </Datagrid>
    </List>
);