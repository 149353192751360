import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex, AutocompleteInput,
    email,
    choices,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const PolicyMailConfigEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} title="Policy Mail Config">
            <SimpleForm redirect="list"
                variant="outlined">
                <TextInput validate={required()} source="fromMailId" label="From Mail Id" fullWidth={true} />
                <ReferenceInput source="insurerId" reference="insurers"  
                perPage={500} sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
                </ReferenceInput>
                <TextInput validate={required()} source="mailSubject" label="Mail Subject" fullWidth={true} />
                <TextInput multiline validate={required()} source="mailBody" label="Mail Body" fullWidth={true} />
            </SimpleForm>
        </Edit>
    );
}