import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const AllotmentCreate = props => {
    const classes = useStyles({});
    
    const initialValues = {
        calimOrdId: props.calimOrdId,
        otherDetails:"",
        isInsured:false,
        isHospitalised:false,
        foClaimFeeAmount:0,
        foIncentiveAmount:0,
        foOtherAmount:0,
        fotaAmount:0,
        allotedOn: moment().toISOString(),
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let userId= localStorage.getItem("userId");
        let m = moment(new Date());
        if(formdata.Reallot1Date != null){
            formdata.Reallot1Date = moment(formdata.Reallot1Date).toISOString();
        }
        if(formdata.Reallot2Date != null){
            formdata.Reallot2Date = moment(formdata.Reallot2Date).toISOString();
        }
        
        let data = {
            calimOrdId: parseInt(formdata.calimOrdId),
            employeeId: formdata.employeeId,
            otherDetails: formdata.otherDetails,
            isInsured: formdata.isInsured,
            isHospitalised: formdata.isHospitalised,
            iscompleted: formdata.iscompleted,
            isReallotment1:formdata.IsReallotment1,
            reallot1Date:formdata.Reallot1Date,
            reallot1Reason:formdata.Reallot1Reason,
            isReallotment2:formdata.IsReallotment2,
            reallot2Date:formdata.Reallot2Date,
            reallot2Reason:formdata.Reallot2Reason,
            isVerified: false,
            isDocCopyReceived:false,
            foVerifyRemarks: "",
            createdDate: moment().toISOString(),
            createdBy: parseInt(userId), 
            allotedOn :moment(formdata.allotedOn).toISOString()
        }
        apiClient.addEntiyRecord("claim-ord-allots", data).then(res => {
            //console.log(res);
            props.onClose();
        }).catch(err => {
            console.log(err);
        });
    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">

                    <DateInput disabled label="Alloted On" source="allotedOn"  validate={ [required()] }  fullWidth={ true } formClassName={ classes.first_inline_input } />
                    
                    <ReferenceInput
                        perPage={ 25 } sort={ { field: 'name', order: 'ASC' } }
                        filterToQuery={ searchText => ({ 'name~like': searchText }) }
                        validate={ [required()] } label="Employee Name" source="employeeId" reference="employees" fullWidth={ true }
                        formClassName={ classes.last_inline_input }
                        >
                        <AutocompleteInput optionText="name" shouldRenderSuggestions={ (val) => { return val && val.trim().length >= 1 } } />
                    </ReferenceInput>

                <TextInput source="otherDetails" label="Remarks" fullWidth={ true } />
                <BooleanInput source="isInsured" label="Insured Part" fullWidth={ true } formClassName={ classes.one_three_input } />
                <BooleanInput source="isHospitalised" label="Hospital Part" fullWidth={ true } formClassName={ classes.two_three_input } />
                <BooleanInput source="iscompleted" label="Whole Part " fullWidth={ true } formClassName={ classes.last_three_input } />

                <BooleanInput label="Reallotment 1" source="IsReallotment1" fullWidth={ true } formClassName={ classes.one_three_input } />
                <DateInput label="Reallotment 1 Date" source="Reallot1Date" fullWidth={ true } formClassName={ classes.two_three_input } />
                <TextInput label="Reallotment 1 Reason" multiline source="Reallot1Reason" fullWidth={ true }  />
                
                <BooleanInput label="Reallotment 2" source="IsReallotment2" fullWidth={ true } formClassName={ classes.one_three_input } />
                <DateInput label="Reallotment 2 Date" source="Reallot2Date" fullWidth={ true } formClassName={ classes.two_three_input } />
                <TextInput label="Reallotment 2 Reason" multiline source="Reallot2Reason" fullWidth={ true } />


            </SimpleForm>
        </Create>
    );
}
export default AllotmentCreate;