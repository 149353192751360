import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,SelectInput,
    Create, SimpleForm, TextInput, DateInput,ReferenceInput,
    BooleanInput, NumberInput, ImageInput, ImageField
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

export const DistrictCreate = props => (
    <Create {...props}  title="District">
        <SimpleForm redirect="list"  
        variant="outlined">
            <TextInput validate={required()} source="code" label="District Code" fullWidth={true} />
            <TextInput validate={required()} source="name" label="District Name" fullWidth={true} />
            <ReferenceInput source="stateId" reference="states">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <BooleanInput initialValue={true} source="isActive" />
        </SimpleForm>
    </Create>
);