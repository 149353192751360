import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar"; 
import { useStyles } from "../../formStyles";

export const LookupCreate = (props) => {
  const initialValues = {
    isModByUser: 1
}
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  const classes = useStyles({});
  return (
    <Create  {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />}  variant="outlined"    initialValues={initialValues}
     >
      <TextInput source="code" validate={[required()]} fullWidth={true} formClassName={classes.one_4_input}   />
        <TextInput source="type" validate={[required()]} fullWidth={true} formClassName={classes.two_4_input}   />
        <TextInput source="name" validate={[required()]} fullWidth={true} formClassName={classes.three_4_input}  />
        <TextInput source="value" validate={[required()]} fullWidth={true} formClassName={classes.last_4_input} />
        <TextInput source="value1" fullWidth={true} formClassName={classes.one_4_input} />
        <TextInput source="narration"  disabled fullWidth={true} formClassName={classes.two_4_input} />
      </SimpleForm>
    </Create>
  );
};
