import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";
import exportFromJSON from 'export-from-json'
import "./report.css";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const ModuleExport = (props: any) => {
    // const classes = useStyles();
    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');

    const [modules, setmodules] = useState<any>([]);
    const [module, setmodule] = useState<any>(0);
    const [reportType, setReportType] = useState<any>("Pending");
    



    const getmodules = () => {
        apiClient.getdataWithOrder("modules", 'modName', 'ASC').then(res => {
            setmodules(res);
        }).catch(err => {
            console.log(err);
        });
    }
    // const generateReport = () => {

    //     setFectched(false);
    //     let sDate = moment(startdate).format('YYYY-MM-DD');
    //     let eDate = moment(enddate).format('YYYY-MM-DD');
    //     if (startdate === "") {
    //         sDate = "";
    //     }
    //     if (enddate === "") {
    //         eDate = "";
    //     }

    //     let data = {
    //         fromDate: sDate,
    //         toDate: eDate,
    //         moduleId: module 

    //     };

    //     setLoading(true);

    //     apiClient.loadReport("/report/leavereport", data).then(res => {
    //         console.log(res);
    //         setData(res);
    //         setFectched(true);
    //         if (res.length > 0) {
    //             let keys = Object.keys(res[0]);
    //             setCols(keys);
    //         }
    //         setLoading(false);

    //     }).catch(err => {
    //         console.log(err);
    //         setLoading(false);
    //     });
    // }

    
    const generateReport = () => {
        setFectched(false);

        let sDate =  moment(startdate).format('YYYY-MM-DD');
        let eDate =  moment(enddate).format('YYYY-MM-DD');
        if(startdate==="")
        {
            sDate="";
        }
        if(enddate ==="")
        {
            eDate="";
        }
        if(module==0)
        {
            alert("Please select module")
            return;
        }

        let data = {
            fromDate: sDate,
            toDate: eDate, 
            moduleId: module, 
            rptName:"ModuleExport",
            
        };
        
        setLoading(true);

        apiClient.loadReport("/report/masterreport",data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                //let keys = Object.keys(res[0]);
              //  setCols(keys);
              exportToCSV(res);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }
    useEffect(() => { 
        getmodules(); 
    }, []);
    function exportToCSV(data) {
        const fileName1=modules.filter(x=>x.id==module); 
        const fileName = fileName1[0].modName;
      const exportType = 'xls'
    exportFromJSON({ data, fileName, exportType })  
        
    }

    return (
        <div >
            <div className="report-heading">
                {props.rptTtile}
            </div>

            <div className="filters">
                <div className="filters-input">
                    <div className="start-date">From Created Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> To Created Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                    <FormControl variant="outlined" className={classes.formControl} size="small">
                        <InputLabel id="demo-simple-select-outlined-label">Module</InputLabel>
                            <Select labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" label="module" onChange={(e) => { setmodule(e.target.value); }} value={module} >
                            
                            {modules && modules.filter(x=>x.isReport==true).map((item: any) => {
                                return (<MenuItem value={item.id}>{item.modName}</MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    {" "}
                     


                </div>
                <div style={{ textAlign: "left", marginLeft: "80px" }}>
                    
                </div>
                






                {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div>No Record found</div>}
                {/* <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div> */}
            </div>
            <div className="filter-action">
                <Button variant="contained"
                    onClick={() => {
                        generateReport();
                    }}
                    startIcon={<AssessmentIcon />}
                    color="primary">
                    Export Data
                </Button>
            </div>
            <div className="report-data">

                {isLoading && <CircularProgress />}

                {/* <div className="w3-responsive">

                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div> */}
            </div>
        </div>
    );
};