import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email, Create,
    choices, Button, SelectInput, ReferenceInput, AutocompleteInput,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput, FileInput, FileField,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import moment from 'moment';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import * as apiClient from "../../../common/apiClient";
import PopupCreateToolbar from "../../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../../formStyles";

const ClaimDocumentsCreate = props => {
    const classes = useStyles({});
    const initialValues = {
        calimOrdId: props.calimOrdId
    }
    const handleSubmit = (formdata) => {
        //console.log(formdata);
        let m = moment(new Date());
        apiClient.UploadFile("docUrl", formdata.docUrl).then(res => {
            let documentUrl = res;
            let data = {
                calimOrdId: parseInt(formdata.calimOrdId),
                slno: formdata.slno,
                docName: formdata.docName,
                docTypeId: formdata.docTypeId,
                docPurpId: formdata.docPurpId,
                docUrl: documentUrl,
            }
            apiClient.addEntiyRecord("claim-ord-docs", data).then(res => {
                //console.log(res);
                props.onClose();
            }).catch(err => {
                console.log(err);
            });

        }).catch(err => {
            console.log(err);
        });

    }
    return (
        <Create { ...props }>
            <SimpleForm
                onSubmit={ handleSubmit }
                initialValues={ initialValues }
                toolbar={ <PopupCreateToolbar label="Save" onSave={ handleSubmit } onClose={ props.onClose } onCancel={ props.onCancel } /> }
                variant="outlined">
                <FileInput fullWidth={ true } source="docUrl" label="Document">
                    <FileField source="docUrl" title="File" />
                </FileInput>

                <TextInput source="slno" label="SL No" fullWidth={ true } />
                <ReferenceInput
                    validate={[required("Please select ")]}
                    perPage={ 500 } sort={ { field: 'name', order: 'ASC' } }
                    label="Purpose Type" source="docPurpId" reference="lookups" 
                    filter={{ type: 'CLPUR' }} fullWidth={ true } >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput source="docName" label="Document name" fullWidth={ true } />
                <ReferenceInput
                    perPage={ 500 } sort={ { field: 'name', order: 'ASC' } }
                    label="Document Type" source="docTypeId" reference="lookups" 
                    filter={{ type: 'DT' }} fullWidth={ true } >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
}
export default ClaimDocumentsCreate;